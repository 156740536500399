import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 495 495" fill="#e64a19" {...props}>
    <path
      d="M256,8.5C119.31,8.5,8.5,119.31,8.5,256S119.31,503.5,256,503.5,503.5,392.69,503.5,256,392.69,8.5,256,8.5ZM429,294H219V46h74V220H429Z"
      transform="translate(-8.5 -8.5)"
    />
  </svg>
);
Icon.displayName = "Icon.Reminder";
export default Icon;
