import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 506.5 503.06" {...props}>
    <polygon
      fill="#fff"
      points="11.36 400.06 11.36 150.06 184.36 8.06 251.36 8.06 419.36 152.06 419.36 400.06 11.36 400.06"
    />
    {/* <path
            fill="#0099a9"
            d="M96.18,245.94"
            transform="translate(-5.64 -6.94)"
        /> */}
    <path
      fill="#6f6f6f"
      className="invert-fill"
      d="M323.3,416c-118.82,0-293.45-.07-313.45-.08A3.9,3.9,0,0,1,6,412s-.31-243.41-.33-256.32a3.87,3.87,0,0,1,1.47-3.05c12-9.53,169.3-139.26,176-144.78A3.9,3.9,0,0,1,185.57,7l68.08-.05a3.9,3.9,0,0,1,2.46.87l1.36,1.1c38.82,31.55,166.31,135.18,177,143.87a3.87,3.87,0,0,1,1.43,3c-.07,8.69,0,80.82,0,150.57,0,39.29,0,78.63,0,107.52,0,2-1.47,2-4.15,2l-6.24,0-23.21,0ZM25.22,396.16H416.47c0-.31,0-.68,0-1,0-33.77-.12-69-.24-103.06-.12-33.62-.24-68.37-.24-101.75-15.74,13.35-82.68,70.11-87.6,74.18a7.53,7.53,0,0,1-4.87,1.86H119.23c-.77,0-5-.31-6.79-1.78-4.16-3.45-71.6-60.78-87.43-74.24,0,24.35.21,144.92.21,205.13v.25C25.22,395.86,25.22,396,25.22,396.16ZM189.73,26c-13.39,11.27-147.25,124.56-163,137.9a.21.21,0,0,0,0,.33c14.73,12.86,88.42,77.19,93.18,81.18a3.38,3.38,0,0,0,2.23,1h.27c.25,0,.5,0,.76,0l1,0H317.12l1.2,0a4.79,4.79,0,0,0,.77,0,2.07,2.07,0,0,0,.81-.26c4.26-3.41,29.6-24,41.78-33.84l3.86-3.13v0c.24-.21.52-.44.83-.7l1.13-.92c8.24-6.84,41.06-37.16,47.82-43.41L250.27,26Z"
      transform="translate(-5.64 -6.94)"
    />
    <path
      fill="#6f6f6f"
      className="invert-fill"
      d="M8,412c0-22.6-.31-240.25-.33-256.32a1.88,1.88,0,0,1,.72-1.49c11.82-9.4,165.92-136.47,176-144.8a1.88,1.88,0,0,1,1.2-.43l68.08-.05a1.88,1.88,0,0,1,1.19.42c10.16,8.25,166.47,135.31,178.32,145a1.87,1.87,0,0,1,.69,1.47c-.11,13.52,0,177,0,258.11,0,.17-393.38,0-424,0A1.89,1.89,0,0,1,8,412ZM119.23,264.35c-1.3,0-4.5-.47-5.52-1.32C109,259.1,23,186,23,186s.22,141.85.22,209.46c0,.29,0,.59,0,.88.07,1.5.29,1.74,1.75,1.81.7,0,1.41,0,2.11,0h388.4c3,0,3,0,3-3,0-67.55-.48-138.58-.48-206.13v-3s-85.27,72.32-90.88,77a5.53,5.53,0,0,1-3.61,1.4m-6.39-16a14.46,14.46,0,0,0,2.29-.05,3.85,3.85,0,0,0,1.75-.68c5.18-4.14,42-34,47.1-38.16,8.75-7.09-8.74,7.1,0,0,4.87-4-4.88,3.95,0,0h0c7.25-5.87,40.84-36.89,48.54-44a1.89,1.89,0,0,0-.07-2.84L251,24H189C179.52,32,44,146.65,25.44,162.37a2.21,2.21,0,0,0,0,3.36c13.12,11.45,88.42,77.18,93.21,81.21a5.17,5.17,0,0,0,4,1.46c.52,0,1.06,0,1.59,0"
      transform="translate(-5.64 -6.94)"
    />
    {/* <path
      style={{ fill: "none", stroke: "#f7931e", strokeMiterlimit: "10" }}
      d="M425.5,166.5"
      transform="translate(-5.64 -6.94)"
    />
    <path
      style={{ fill: "none", stroke: "#f7931e", strokeMiterlimit: "10" }}
      d="M321.5,256.5"
      transform="translate(-5.64 -6.94)"
    />
    <path
      style={{ fill: "none", stroke: "#f7931e", strokeMiterlimit: "10" }}
      d="M116.5,256.5"
      transform="translate(-5.64 -6.94)"
    /> */}
    <polygon
      fill="#fff"
      points="434.55 503.06 370.93 441.44 506.5 441.01 506.5 359.11 402.36 359.06 405.36 298.06 314.47 297.02 215.36 389.06 215.36 408.06 314.48 503.06 434.55 503.06"
    />
    <polygon
      fill="#9868b9"
      className="invert-fill"
      points="491.5 374.06 334 373.56 397 312.56 320.5 312.06 229 400.56 320.5 488.06 397.5 488.06 334 426.56 491.5 426.06 491.5 374.06"
    />
  </svg>
);
Icon.displayName = "Icon.Email.Replied";
export default Icon;
