import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 498.36 499.41" {...props}>
    <path
      fill="#c83232"
      d="M1.66,257.86q0-108.37,0-216.74C1.68,17.51,11,8.18,34.54,8.18c79.09,0,158.17.11,237.26-.08,22.36-.05,41,7.67,56.67,23.57q38.83,39.38,78.23,78.19,22.63,22.21,22.65,53.44.24,157.43,0,314.85c0,19.79-10.12,29.33-30.06,29.33q-183.29.05-366.59,0c-21.46,0-31-9.42-31-31.11Q1.56,367.12,1.66,257.86Zm391.8,213.72V186.4H285c-25,0-33.54-8.58-33.56-33.92q0-49,0-98.07c0-3.41,0-6.81,0-10.29H37.5V471.59ZM287.24,150.42h105c-3.06-4.9-4.88-9.39-8-12.56q-42-42.4-84.49-84.3c-3.09-3-7.47-4.79-12.48-7.9Z"
      transform="translate(-1.64 -8.1)"
    />
    <rect fill="#c83232" x="62.36" y="219.9" width="436" height="210" />
    <path
      className="invert-fill"
      fill="#fff"
      d="M136.52,355.18V404H104.61V262.3h50q53.55,0,53.55,45.15,0,21.34-15.36,34.53t-41.05,13.19Zm0-68.37V331h12.55q25.49,0,25.49-22.33,0-21.84-25.49-21.84Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      fill="#fff"
      className="invert-fill"
      d="M228.9,404V262.3h50.19q75.48,0,75.48,69.06,0,33.1-20.6,52.86T279.09,404Zm31.91-115.7V378.1h15.81q20.75,0,32.56-12.45T321,331.76q0-20.25-11.71-31.86t-32.85-11.61Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      fill="#fff"
      className="invert-fill"
      d="M460,288.29H410v34.78h45.94V349H410v55H378.09V262.3H460Z"
      transform="translate(-1.64 -8.1)"
    />
  </svg>
);
Icon.displayName = "Icon.Pdf";
export default Icon;
