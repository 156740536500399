// @flow
import React from "react";
import Library from "./Library";
import { type SortDirectionEnum, SortDirectionOptions } from "@hs/types";
import loc from "i18next";

const Icon = ({
  name,
  isSorted,
  sortDirection,
  pullRight,
  style,
}: {
  name: string,
  isSorted: boolean,
  sortDirection: SortDirectionEnum,
  pullRight?: boolean,
  style?: Object,
}) => {
  let sortIcon = "fa-solid fa-sort";
  if (isSorted) {
    sortIcon =
      sortDirection === SortDirectionOptions.Ascending
        ? "fa-solid fa-sort-up"
        : "fa-solid fa-sort-down";
  }
  const props = {
    title: loc.t("document:sortBy", {
      name,
    }),
    className: undefined,
    name: sortIcon,
    // $FlowFixMe
    style: Object.assign(
      {
        opacity: isSorted ? 1 : 0.2,
        marginTop: "3px",
      },
      style
    ),
  };
  if (pullRight === true) props.className = "pull-right";
  return <Library {...props} />;
};

Icon.displayName = "Icon.Sort";
export default Icon;
