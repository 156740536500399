import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 456 496.29" {...props}>
    <path
      fill="#0099a9"
      d="M386.9,158.9A97,97,0,0,0,308,199.45l-94.44-54.53A97.09,97.09,0,1,0,115,201.52v109a97.12,97.12,0,1,0,98.82,57.15L308.38,313A97.08,97.08,0,1,0,386.9,158.9ZM203.83,350.3A97.13,97.13,0,0,0,135,310.45V201.55a97.12,97.12,0,0,0,68.47-39.36l94.6,54.62a97,97,0,0,0,.25,78.93Z"
      transform="translate(-28 -7.86)"
    />
    <path fill="#6f6f6f" d="M-107.67,36" transform="translate(-28 -7.86)" />
  </svg>
);
Icon.displayName = "Icon.Share";
export default Icon;
