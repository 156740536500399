import React, { useMemo, useState, useCallback, useRef } from "react";
import Icons from "@hs/icons";
import Dropdown from "@hs/dropdown";
import CloseButton from "@hs/close-button";
import MenuItem from "react-bootstrap/lib/MenuItem";
import ActionButton from "./ActionButton";
import styles from "./FormBar.module.css";

export type FormAction = {
  id: string;
  title: string;
  icon?: string;
  description?: string;
  onClick?: () => void;
};

export type Components = {
  Container: React.ElementType;
  Icon: React.ElementType;
  Header: React.ElementType;
};

export type FormBarProps = {
  className?: string;
  icon?: string;
  title?: string;
  description?: string;
  children?: any;
  actions?: Array<FormAction>;
  onClose?: () => void;
  closeTitle?: string;
  components?: Partial<Components>;
};

const FormBar = ({
  className,
  icon,
  title,
  description,
  children,
  actions,
  onClose,
  closeTitle,
  components,
}: FormBarProps) => {
  const Components: Components = useMemo(
    () => ({
      Container: components?.Container ?? "nav",
      Icon: components?.Icon ?? Icons.Library,
      Header: components?.Header ?? "h2",
    }),
    [components]
  );
  const ref = useRef<HTMLDivElement>(null);
  const [hiddenActions, setHiddenActions] = useState<Array<FormAction>>([]);
  const hasOverflow = hiddenActions.length > 0;
  const handleIntersectionChange = useCallback(
    (isIntersecting: boolean, action: FormAction) => {
      setHiddenActions((actions: Array<FormAction>) => {
        const newActions = actions.filter(
          (a: FormAction) => a.id !== action.id
        );
        if (!isIntersecting) {
          newActions.unshift(action);
        }
        return newActions;
      });
    },
    []
  );
  return (
    <Components.Container
      className={`form-bar ${styles.container}${
        className ? ` ${className}` : ""
      }`}
      role="menubar"
    >
      <div className={`form-bar__wrapper ${styles.wrapper}`}>
        {typeof onClose === "function" && (
          <CloseButton
            onClick={onClose}
            title={closeTitle}
            className={styles.close}
          />
        )}
        {icon && (
          <Components.Icon
            name={icon}
            className={styles.icon}
            title={description ?? title}
          />
        )}
        {title && (
          <Components.Header
            className={styles.header}
            title={description ?? title}
          >
            {title}
          </Components.Header>
        )}
        {children && <div className={styles.children}>{children}</div>}
        {actions != null && actions.length > 0 && (
          <>
            <div
              ref={ref}
              className={`form-bar__actions actions ${styles.actions}${
                hasOverflow ? ` ${styles.overflow}` : ""
              }`}
            >
              <div className="actions__wrapper">
                {actions.map((action) => (
                  <ActionButton
                    key={`action.button.${action.id}`}
                    className={`action__${action.id} ${styles.button}`}
                    action={action}
                    onIntersectionChange={handleIntersectionChange}
                    node={ref.current as Element}
                    Components={Components}
                  />
                ))}
              </div>
            </div>
            {hasOverflow && (
              <Dropdown
                className={`actions__menu ${styles.menu}`}
                placement="bottom-end"
              >
                <Dropdown.Toggle caret={false} chrome>
                  <Icons.Library name="fa-solid fa-ellipsis" />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {hiddenActions.map((action, idx) => (
                    <MenuItem
                      eventKey={idx + 1}
                      key={`action.menu.${action.id}`}
                      onClick={action.onClick}
                      title={action.title}
                    >
                      {action.icon && <Components.Icon name={action.icon} />}
                      {action.title}
                    </MenuItem>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        )}
      </div>
    </Components.Container>
  );
};

FormBar.displayName = "FormBar";
export default FormBar;
