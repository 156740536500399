import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 431.6 339.39" fill="#e64a19" {...props}>
    <path
      d="M215.8,425.38c-57.81,0-115.62-.58-173.41.31-20.7.32-42.1-15-42-41.12.36-86.07,0-172.13.23-258.2.07-24.75,16.15-40,41-40q174.58-.07,349.15,0c24.85,0,40.91,15.23,41,40.09q.4,129.48,0,259c-.09,24.86-16.19,40-41,40Q303.28,425.43,215.8,425.38ZM216,117q-86.68,0-173.36,0C33,117,31,119.35,31.41,129c1,25.91,13,46.29,32.6,62.09,41,33,82.13,65.83,123.78,98,23.25,17.95,33.8,17.69,57.11-.15q43.72-33.46,86.37-68.31C348.72,206.39,366.18,192,382,176,393.76,164,401.18,148.74,401.39,131c.14-11.82-1.9-14-13.54-14ZM31,203c0,3.63,0,6.33,0,9q0,84,0,168c0,13.12,1.55,14.66,14.55,14.66H386.93c13,0,14.48-1.54,14.49-14.73q0-84.37,0-168.75c0-2.46-.23-4.93-.39-8.21-7.66,6.6-14.41,12.77-21.53,18.48-33,26.5-66,53.11-99.4,79.16A346,346,0,0,1,242.6,326,49.09,49.09,0,0,1,210,332.08c-20.2-3-36.16-13.86-51.44-26.16C116.36,272,74.3,238,31,203Z"
      transform="translate(-0.4 -86.3)"
    />
  </svg>
);
Icon.displayName = "Icon.FileExt.";
export default Icon;
