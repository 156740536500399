import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 16 16" {...props}>
    <path
      d="M15.073 9.883a3.23 3.141 0 00-2.759-3.057A4.04 3.928 0 008.19 3.005a4.134 4.02 0 00-3.93 2.651A3.827 3.722 0 00.927 9.325a3.882 3.775 0 003.985 3.668h6.797a1.147 1.116 0 00.173 0 3.246 3.156 0 003.19-3.11z"
      fill="none"
      stroke="#6f6f6f"
    />
  </svg>
);
Icon.displayName = "Icon.Cloud";
export default Icon;
