//@flow
import React from "react";

const Icon = (props: any) => (
  <svg viewBox="0 0 452.92 508" {...props}>
    <path
      d="M180.43,263.72A90.21,90.21,0,1,0,90.2,173.51,90.22,90.22,0,0,0,180.43,263.72Z"
      transform="translate(-29.54 -2)"
    />
    <path
      d="M288.14,162.6A84.26,84.26,0,1,0,239.53,85,104.44,104.44,0,0,1,288.14,162.6Z"
      transform="translate(-29.54 -2)"
    />
    <path
      d="M367,182.61h-80.1a108.72,108.72,0,0,1-35.06,76.75C309.64,276,352,328.07,352,389.53v28.59c76.62-2.72,120.78-23.79,123.69-25.2l6.16-3h.66V298.12A115.5,115.5,0,0,0,367,182.61Z"
      transform="translate(-29.54 -2)"
    />
    <path
      d="M221.3,274.19H145A115.51,115.51,0,0,0,29.54,389.7v91.74l.24,1.45,6.44,2C96.9,503.72,149.63,510,193,510c84.76,0,133.89-24,136.92-25.52l6-3h.64V388.67C336.63,325.55,284.89,274.19,221.3,274.19Z"
      transform="translate(-29.54 -2)"
    />
  </svg>
);
Icon.displayName = "Icon.ElementType.Department";
export default Icon;
