import React from "react";
import loc from "i18next";
import styles from "./CloseButton.module.css";

export type CloseButtonProps = {
  onClick: () => void;
  className?: string;
  title?: string;
};

const CloseButton = ({
  onClick,
  className,
  title = loc.t("common:close") || "",
}: CloseButtonProps) => (
  <button
    type="button"
    className={`close ${styles.button}${className ? ` ${className}` : ""}`}
    onClick={onClick}
    title={title}
    data-test="closeButton"
  >
    <span aria-hidden="true">×</span>
    <span className="sr-only">{title}</span>
  </button>
);
CloseButton.displayName = "CloseButton";
export default CloseButton;
