import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 379.56 495" {...props}>
    <path fill="#6f6f6f" d="M-107.67,36" transform="translate(-66.22 -8.5)" />
    <path
      fill="#c83232"
      d="M405.82,208.74c4.2.74,8.11,1,11.79,2.14,17.07,5.09,28,19.53,28,37.39.09,72-.11,144,.14,215.94.07,21.16-17.65,39.32-39.55,39.28q-149-.27-297.93-.1c-21.9,0-37.46-12.16-41.35-32.08a44.82,44.82,0,0,1-.55-8.53c0-71.09.25-142.18-.19-213.26-.12-20,16.12-40.11,37.61-40,4.15,0,5.53-1.39,5.47-5.62-.23-15.71.1-31.44-.13-47.15-.49-33,9.92-62.43,29.53-88.6,23.89-31.88,55.94-51.09,95.07-57.67,79-13.27,155.26,40.71,168.8,119.73,2.74,16,2.46,32.58,3.17,48.9C406.17,188.92,405.82,198.74,405.82,208.74Zm-65.14.33c.09-2,.23-3.61.23-5.19,0-16.6.47-33.22-.21-49.79a89.46,89.46,0,0,0-4.44-25C321.59,86.24,274.77,62.7,230.86,77.2c-33.72,11.14-57.68,43.26-57.57,78.74,0,15.53.23,31.06-.1,46.59-.11,5.26,1.09,7,6.72,7,51.41-.22,102.82-.13,154.23-.15C336.23,209.39,338.32,209.19,340.68,209.07Zm-102,188.78h0q0,11.52,0,23c0,.36,0,.71,0,1.07.36,8.21,5.44,14.95,13,17.19,13.36,4,24.31-4.14,24.49-18.57.18-14.64,0-29.28-.19-43.92-.05-4.73,1.13-8.43,5.12-11.6a36.74,36.74,0,0,0,9.27-46.35C281.68,302.87,262.23,295,245.39,301c-13.28,4.7-21.91,14.13-24.63,28s1.18,26.29,12.29,35.6c4.07,3.41,6,7,5.71,12.39C238.39,383.91,238.67,390.89,238.67,397.85Z"
      transform="translate(-66.22 -8.5)"
    />
  </svg>
);
Icon.displayName = "Icon.Locked";
export default Icon;
