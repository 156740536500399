// @flow
import React, { useState, useCallback, useMemo } from "react";
import { createPortal } from "react-dom";
import * as PopperJS from "@popperjs/core";
import { usePopper } from "react-popper";
import Toggle from "./Toggle";
import Menu from "./Menu";
import RootCloseWrapper from "react-overlays/lib/RootCloseWrapper";
import { getClassNames } from "../data/utils";

export type DropdownProps = {
  /** html id */
  id?: string;
  /** bootstrap size */
  bsSize?: string;
  /** whether menu should be toggled on hover */
  openMenuOnHover?: boolean;
  /** Dropdown children */
  children?: React.ReactChild | React.ReactChild[];
  /** button's tooltip */
  title?: string;
  /** CSS class to apply to top container */
  className?: string;
  /** whether to render toggle button as disabled */
  disabled?: boolean;
  /** additional styles for menu */
  menuStyle?: React.CSSProperties;
  /** placement */
  placement?: PopperJS.Placement;
};

const Dropdown = ({
  id,
  bsSize,
  className,
  children,
  title,
  disabled,
  openMenuOnHover = true,
  menuStyle = {},
  placement = "bottom-start",
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleIsOpen = useCallback(() => setIsOpen((s) => !s), []);
  const handleRootClose = useCallback(() => setIsOpen(false), []);
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] =
    useState<HTMLUListElement | null>(null);
  const boundary = useMemo(() => document.getElementById("root"), []);
  const options = useMemo(
    () => ({
      modifiers: [
        {
          name: "preventOverflow",
          options: {
            rootBoundary: "document",
            placement,
          },
        },
      ],
      placement,
    }),
    [placement]
  );
  const popper = usePopper(referenceElement, popperElement, options);
  const combinedPopperStyles = useMemo(
    () => Object.assign({}, popper.styles.popper, menuStyle),
    [popper.styles.popper, menuStyle]
  );
  return (
    <RootCloseWrapper disabled={false} onRootClose={handleRootClose}>
      <div
        title={title}
        className={getClassNames(
          "dropdown",
          "btn-group",
          className,
          disabled ? "disabled" : ""
        )}
      >
        {React.Children.map(children, (item, index) => {
          const child = item as unknown as React.ReactElement<
            React.PropsWithChildren<any>
          >;
          switch (child.type as unknown) {
            case Toggle:
              return (
                <Toggle
                  {...child.props}
                  id={id}
                  disabled={disabled}
                  setRef={setReferenceElement}
                  onClick={toggleIsOpen}
                  className={getClassNames(
                    bsSize === "small" ? "btn-sm" : "",
                    isOpen ? "expanded" : ""
                  )}
                />
              );
            case Menu:
              return createPortal(
                isOpen ? (
                  <Menu
                    {...child.props}
                    {...popper.attributes.popper}
                    style={combinedPopperStyles}
                    className={className}
                    setRef={setPopperElement}
                  />
                ) : null,
                boundary as HTMLElement
              );
            default:
              console.error(
                `Unsupported child of type ${
                  typeof child.type === "string" ? child.type : child.type.name
                } for @hs/dropdown!`
              );
              return null;
          }
        })}
      </div>
    </RootCloseWrapper>
  );
};

Dropdown.displayName = "Dropdown";
Dropdown.Toggle = Toggle;
Dropdown.Menu = Menu;
export default Dropdown;
