import React from "react";
import { getClassNames } from "../data/utils";
import styles from "./Toggle.module.css";

export type ToggleProps = {
  children?: React.ReactChild | React.ReactChild[];
  className?: string;
  setRef?: () => void;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  id?: string;
  disabled?: boolean;
  caret?: boolean;
  chrome?: boolean;
};

const Toggle = ({
  children,
  className,
  setRef,
  onClick,
  id,
  disabled,
  caret = true,
  chrome = false,
}: ToggleProps) => (
  <button
    id={id}
    type="button"
    ref={setRef}
    onClick={onClick}
    disabled={disabled}
    className={getClassNames(
      "dropdown-toggle",
      "btn",
      "btn-default",
      chrome ? styles.chromed : "",
      className
    )}
  >
    {children}
    {caret && <span className={`${styles.caret} caret`} />}
  </button>
);

Toggle.displayName = "Dropdown.Toggle";
export default Toggle;
