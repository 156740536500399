import Cloud from "./Cloud";
import Custom from "./Custom";
import CustomActionReminder from "./CustomActionReminder";
import ElementType from "./ElementType";
import Email from "./Email";
import Error from "./Error";
import FileExt from "./FileExt";
import FileSignature from "./FileSignature";
import Library from "./Library";
import Link from "./Link";
import Locked from "./Locked";
import Notes from "./Notes";
import Pdf from "./Pdf";
import Relation from "./Relation";
import Reminder from "./Reminder";
import Share from "./Share";
import Site from "./Site";
import Sort from "./Sort";
import Spinner from "./Spinner";
import SyncStatus from "./SyncStatus";
import WorkflowReport from "./WorkflowReport";

const Icons = {
  Cloud,
  Custom,
  CustomActionReminder,
  ElementType,
  Email,
  Error,
  FileExt,
  FileSignature,
  Library,
  Link,
  Locked,
  Notes,
  Pdf,
  Relation,
  Reminder,
  Share,
  Site,
  Sort,
  Spinner,
  SyncStatus,
  WorkflowReport,
};
export default Icons;
