import React from "react";

const Icon = (props: any) => (
  <svg viewBox="0 0 511 511" {...props}>
    <path
      d="M336.5,68.5V.5h-167v68H62.5v68h387v-68Zm-34,0h-99v-35h99Z"
      transform="translate(-62.5 -0.5)"
    />
    <path
      d="M95,511.5H417v-359H95ZM320,221.25a17.75,17.75,0,0,1,17.75-17.75h11.54A17.75,17.75,0,0,1,367,221.25v222.5a17.75,17.75,0,0,1-17.75,17.75H337.71A17.75,17.75,0,0,1,320,443.75Zm-88.85-1a17.75,17.75,0,0,1,17.75-17.75H260.4a17.75,17.75,0,0,1,17.75,17.75v222.5A17.75,17.75,0,0,1,260.4,460.5H248.86a17.75,17.75,0,0,1-17.75-17.75Zm-87.11,0a17.75,17.75,0,0,1,17.75-17.75h11.54A17.75,17.75,0,0,1,191,220.25v222.5a17.75,17.75,0,0,1-17.75,17.75H161.75A17.75,17.75,0,0,1,144,442.75Z"
      transform="translate(-62.5 -0.5)"
    />
  </svg>
);
Icon.displayName = "Icon.Site.RecycleBin";
export default Icon;
