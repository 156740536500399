import React, { useMemo } from "react";
import Library from "./Library";
import ElementType from "./ElementType";
import Site from "./Site";
import FileExt from "./FileExt";

const Icon = ({
  icon,
  elementType,
  siteType,
  ext,
  color,
  style: styleFromProps = {},
  ...additionalProps
}) => {
  const props = useMemo(() => {
    const _ = { ...additionalProps };
    const style = { ...styleFromProps };
    /* istanbul ignore next */
    if (color) {
      style.color = color;
      style.fill = color;
      // set fill color if set
      if (elementType != null || siteType != null || ext != null) {
        _.fill = color;
      }
    }
    /* istanbul ignore next */
    if (Object.keys(style)) _.style = style;
    return _;
  }, [color, styleFromProps, elementType, siteType, ext, additionalProps]);
  if (icon?.startsWith("https:") || icon?.startsWith("data:"))
    return <img src={icon} {...props} />;
  else if (icon != null) return <Library name={icon} {...props} />;
  else if (elementType != null)
    return <ElementType elementType={elementType} {...props} />;
  else if (siteType != null) return <Site siteType={siteType} {...props} />;
  else if (ext != null) return <FileExt ext={ext} {...props} />;
  return null;
};

Icon.displayName = "Icon.Custom";
export default Icon;
