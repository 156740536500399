import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 427.81 499.41" fill="#4d4d4d" {...props}>
    <path
      d="M1.66,257.86q0-108.37,0-216.74C1.68,17.51,11,8.18,34.54,8.18c79.09,0,158.17.11,237.26-.08,22.36-.05,41,7.67,56.67,23.57q38.83,39.38,78.23,78.19,22.63,22.21,22.65,53.44.24,157.43,0,314.85c0,19.79-10.12,29.33-30.06,29.33q-183.29.05-366.59,0c-21.46,0-31-9.42-31-31.11Q1.56,367.12,1.66,257.86Zm391.8,213.72V186.4H285c-25,0-33.54-8.58-33.56-33.92q0-49,0-98.07c0-3.41,0-6.81,0-10.29H37.5V471.59ZM287.24,150.42h105c-3.06-4.9-4.88-9.39-8-12.56q-42-42.4-84.49-84.3c-3.09-3-7.47-4.79-12.48-7.9Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      d="M247.37,336.85c0,14.93.19,29.86-.06,44.78-.35,21.31-13.44,34.21-34.73,34.29q-44.78.17-89.56,0c-22.06-.07-35.13-12.66-35.3-34.45q-.36-45.58,0-91.16c.19-21.45,13.32-34.22,34.61-34.26q44.78-.09,89.56,0c22.34.05,35.1,12.77,35.45,35.22.12,7.73,0,15.46,0,23.19Q247.38,325.66,247.37,336.85Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      d="M343.42,335.89c0,22.63.23,45.27-.22,67.9-.08,3.9-3,7.74-4.66,11.61-3.91-1.66-8.73-2.36-11.57-5.12-19.85-19.3-39.31-39-58.85-58.63-6.4-6.42-6.44-24.88-.05-31.29,19.73-19.81,39.39-39.69,59.42-59.2,2.63-2.57,8.55-5.34,10.57-4.11,2.92,1.77,5,7.09,5.13,10.95C343.65,290.62,343.42,313.25,343.42,335.89Z"
      transform="translate(-1.64 -8.1)"
    />
  </svg>
);
Icon.displayName = "Icon.FileExt.Video";
export default Icon;
