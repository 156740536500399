//@flow
import React from "react";

const Icon = (props: any) => (
  <svg viewBox="0 0 490 512" {...props}>
    <path d="M460,0H399V210h61a30,30,0,0,0,30-30V30A30,30,0,0,0,460,0Z" />
    <rect x="82" width="290" height="512" />
    <rect width="55" height="512" />
  </svg>
);
Icon.displayName = "Icon.ElementType.Register";
export default Icon;
