import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 427.85 499.41" fill="#25458e" {...props}>
    <path
      d="M0,249.76V33C0,9.41,9.36.08,32.9.08,112,.08,191.07.19,270.16,0c22.36-.05,41,7.67,56.67,23.57Q365.66,63,405.06,101.76,427.69,124,427.71,155.2q.24,157.43,0,314.85c0,19.79-10.12,29.33-30.06,29.33q-183.29,0-366.59,0c-21.46,0-31-9.42-31-31.11Q-.08,359,0,249.76Zm391.8,213.72V178.3H283.36c-25,0-33.54-8.58-33.56-33.92V36H35.86V463.49ZM285.6,142.32h105c-3.06-4.9-4.88-9.39-8-12.56q-42-42.4-84.49-84.3c-3.09-3-7.47-4.79-12.48-7.9Z"
      transform="translate(0.02 0)"
    />
    <path
      d="M132.16,427.74,91.67,264.9c-.07-.28-.08-.57-.14-.85-3.32-13.73-3.32-13.72-17.33-14.24-1.38,0-2.75-.28-4.56-.48v-27.6h79.72v27.78H125.55L155.42,383.2c.69-2,1.33-3.63,1.76-5.28q15.9-61.66,31.77-123.32c2.37-9.17,5.21-18.22,7.18-27.47,1.07-5,3.45-6.17,8.24-6.23,24-.34,24.06-.49,30,22.62Q252.12,312.25,269.76,381c.12.45.73.77,1.22,1.27l29.58-132.1H277.11V221.69h79.33V248.8a7.42,7.42,0,0,1-2.21.9c-16.09,1.08-16.08,1.07-19.92,16.46L295.37,422.23c-.4,1.62-.86,3.22-1.48,5.49-13.11,0-26,.21-38.85-.25-1.74-.06-4.32-3.26-4.88-5.43q-18.2-69.46-36-139c-.26-1-.56-2-.67-2.36-12.4,47-24.84,94.43-37.58,141.74-.58,2.16-3.89,5-6,5.11C157.62,428,145.29,427.74,132.16,427.74Z"
      transform="translate(0.02 0)"
    />
  </svg>
);
Icon.displayName = "Icon.FileExt.Word";
export default Icon;
