//@flow
import React from "react";

const Icon = (props: any) => (
  <svg viewBox="0 0 512 512" {...props}>
    <polygon points="0 256 257 0 512 257 448 257 448 512 319 512 319 339 192 339 192 512 64 512 64 257 0 256" />
  </svg>
);
Icon.displayName = "Icon.ElementType.Organization";
export default Icon;
