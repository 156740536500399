// @flow
import React, { useMemo } from "react";
import Global from "./Global";
import Mail from "./Mail";
import My from "./My";
import RecycleBin from "./RecycleBin";
import Shares from "./Shares";
import { type SiteTypeEnum, SiteType } from "@hs/types";

type Props = {
  /** Type of Site */
  siteType: SiteTypeEnum,
  /** Custom CSS style */
  style?: StyleSheet | Object,
  /** Custom CSS Class Name */
  className?: string,
};

/** HierarchySiteType image mapping
 *  (SiteType text localizations available in data/i18n.json)
 * */
const Icon = ({ siteType, ...additionalProps }: Props) => {
  const props = useMemo(
    () => ({
      ...additionalProps,
      fill: additionalProps?.fill || "rgb(119, 119, 119)",
      height: "15px",
      width: "15px",
    }),
    [additionalProps]
  );
  switch (siteType) {
    case SiteType.MySites:
      return <My {...props} />;
    case SiteType.SharedSites:
      return <Shares {...props} />;
    case SiteType.RecycleBin:
      return <RecycleBin {...props} />;
    case SiteType.MailBox:
      return <Mail {...props} />;

    case SiteType.NotSet:
    case SiteType.GlobalSites:
    case SiteType.AUSites:
    default:
      return <Global {...props} />;
  }
};
Icon.displayName = "Icon.Site";
Icon.Global = Global;
Icon.Mail = Mail;
Icon.My = My;
Icon.RecycleBin = RecycleBin;
Icon.Shares = Shares;
export default Icon;
