//@flow
import React from "react";

const Icon = (props: any) => (
  <svg viewBox="0 0 512 512" {...props}>
    <circle cx="256" cy="399" r="34.15" />
    <path d="M180,0V512H332V0Zm76,449a50,50,0,1,1,50-50A50,50,0,0,1,256,449Zm50-164H206V46H306Z" />
    <rect x="206" y="46" width="100" height="239" />
    <path d="M256,349a50,50,0,1,0,50,50A50,50,0,0,0,256,349Zm0,84.15A34.15,34.15,0,1,1,290.15,399,34.15,34.15,0,0,1,256,433.15Z" />
    <circle cx="436" cy="399" r="34.15" />
    <path d="M360,0V512H512V0Zm76,449a50,50,0,1,1,50-50A50,50,0,0,1,436,449Zm50-164H386V46H486Z" />
    <rect x="386" y="46" width="100" height="239" />
    <path d="M436,349a50,50,0,1,0,50,50A50,50,0,0,0,436,349Zm0,84.15A34.15,34.15,0,1,1,470.15,399,34.15,34.15,0,0,1,436,433.15Z" />
    <circle cx="76" cy="399" r="34.15" />
    <path d="M0,0V512H152V0ZM76,449a50,50,0,1,1,50-50A50,50,0,0,1,76,449Zm50-164H26V46H126Z" />
    <rect x="26" y="46" width="100" height="239" />
    <path d="M76,349a50,50,0,1,0,50,50A50,50,0,0,0,76,349Zm0,84.15A34.15,34.15,0,1,1,110.15,399,34.15,34.15,0,0,1,76,433.15Z" />
  </svg>
);
Icon.displayName = "Icon.ElementType.Folder";
export default Icon;
