import React from "react";
import "./assets/css/fontawesome.css";
import "./assets/css/solid.css";
import "./assets/css/regular.css";
import "./assets/css/brands.css";

const getIconName = (name) => {
  if (`${name}`.startsWith("fa-")) {
    return name;
  }
  return `fa-solid fa-${name}`;
};

const Icon = ({
  name,
  fixedWidth = false,
  pulse = false,
  size,
  className: classNameFromProps,
  style: styleFromProps = {},
  ...props
}) => {
  const icon = getIconName(name);
  const className = [
    "fa",
    "icon",
    icon,
    classNameFromProps,
    fixedWidth && "fa-fw",
    pulse && "fa-pulse",
    size && `fa-${size}`,
  ]
    .filter((i) => !!i)
    .join(" ");
  const style = Object.assign(
    { animationIterationCount: "500", animationDuration: "800ms" },
    styleFromProps
  );
  return <span className={className} style={style} {...props} />;
};
Icon.displayName = "Icon.Library";
export default Icon;
