import React, { useMemo } from "react";
import Audio from "./Audio";
import Envelope from "./Envelope";
import Excel from "./Excel";
import File from "./File";
import Image from "./Image";
import Pdf from "./Pdf";
import PowerPoint from "./PowerPoint";
import Text from "./Text";
import Video from "./Video";
import Word from "./Word";
import Zip from "./Zip";
import AddressCard from "./AddressCard";

type Props = {
  ext: ?string,
  style?: StyleSheet | Object,
  className?: string,
};

const Icon = ({ ext, ...additionalProps }: Props) => {
  const props = useMemo(
    () => ({
      ...additionalProps,
      height: "15px",
      width: "15px",
    }),
    [additionalProps]
  );
  // https://en.wikipedia.org/wiki/List_of_Microsoft_Office_filename_extensions
  switch (ext == null ? "" : ext.toLowerCase()) {
    case ".txt":
    case ".log":
    case ".xml":
    case ".config":
    case ".xps":
    case ".md":
    case ".csv":
      return <Text {...props} />;
    case ".doc":
    case ".docm":
    case ".docx":
    case ".dot":
    case ".dotm":
    case ".dotx":
    case ".odt":
    case ".rtf":
      return <Word {...props} />;
    case ".ods":
    case ".xlm":
    case ".xls":
    case ".xlsb":
    case ".xlsm":
    case ".xlsx":
    case ".xlt":
    case ".xltm":
    case ".xltx":
    case ".xlw":
      return <Excel {...props} />;
    case ".odp":
    case ".pot":
    case ".potm":
    case ".potx":
    case ".pps":
    case ".ppsm":
    case ".ppsx":
    case ".ppt":
    case ".pptm":
    case ".pptx":
      return <PowerPoint {...props} />;
    case ".mp3":
    case ".wav":
      return <Audio {...props} />;
    case ".mp4":
    case ".mov":
    case ".avi":
    case ".mpg":
    case ".mpeg":
    case ".ogv":
    case ".webm":
    case ".wmv":
      return <Video {...props} />;
    case ".png":
    case ".tif":
    case ".tiff":
    case ".gif":
    case ".ico":
    case ".jpg":
    case ".jpeg":
      return <Image {...props} />;
    case ".pdf":
      return <Pdf {...props} />;
    case ".7z":
    case ".zip":
    case ".tar":
      return <Zip {...props} />;
    case ".msg":
    case ".eml":
    case ".dxl":
      return <Envelope {...props} />;
    case ".vcf":
      return <AddressCard {...props} />;
    default:
      return <File {...props} />;
  }
};
Icon.displayName = "Icon.FileExt";
Icon.Audio = Audio;
Icon.Envelope = Envelope;
Icon.Excel = Excel;
Icon.File = File;
Icon.Image = Image;
Icon.Pdf = Pdf;
Icon.PowerPoint = PowerPoint;
Icon.Text = Text;
Icon.Video = Video;
Icon.Word = Word;
Icon.Zip = Zip;
Icon.AddressCard = AddressCard;
export default Icon;
