import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 427.81 499.41" fill="#ed1c24" {...props}>
    <path
      d="M1.66,257.86q0-108.37,0-216.74C1.68,17.51,11,8.18,34.54,8.18c79.09,0,158.17.11,237.26-.08,22.36-.05,41,7.67,56.67,23.57q38.83,39.38,78.23,78.19,22.63,22.21,22.65,53.44.24,157.43,0,314.85c0,19.79-10.12,29.33-30.06,29.33q-183.29.05-366.59,0c-21.46,0-31-9.42-31-31.11Q1.56,367.12,1.66,257.86Zm391.8,213.72V186.4H285c-25,0-33.54-8.58-33.56-33.92q0-49,0-98.07c0-3.41,0-6.81,0-10.29H37.5V471.59ZM287.24,150.42h105c-3.06-4.9-4.88-9.39-8-12.56q-42-42.4-84.49-84.3c-3.09-3-7.47-4.79-12.48-7.9Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      d="M266.76,288.86c20.58-3.48,41.25-4.54,61.94-1,11.84,2,22.4,6.81,31.1,15.31a33.28,33.28,0,0,1,10.43,24.7c-.06,13.46-10.86,25.28-24.86,28.31a50.64,50.64,0,0,1-26.74-1.41c-17.72-5.67-33.53-15-48.72-25.45-6.17-4.26-12.07-8.91-18.22-13.2-1.2-.84-3.19-1.52-4.48-1.13-29.52,8.93-58,20.35-85.1,35.23a12.6,12.6,0,0,0-4.93,5.37c-9,19.09-18.62,37.85-31.22,54.89-7.9,10.67-16.82,20.3-29.41,25.55-10.92,4.55-24.52,2.17-27.1-14.5-1.65-10.61,1.22-19.88,6.53-28.67a101.73,101.73,0,0,1,26-28.33c11.9-9,24.57-17.06,36.83-25.63,1.76-1.23,3.71-2.81,4.51-4.68,12.84-30,23-60.78,29-92.9a11.22,11.22,0,0,0-1.61-8.33c-11.37-19.51-20.82-39.9-24-62.46a118.53,118.53,0,0,1-.24-27c1.48-16.44,21.7-27.1,35.64-21.49,12.67,5.1,15.73,8.35,16.62,24.1,1.49,26.12,1,52.22-2.68,78.14-.82,5.75.52,9.87,4.08,14.22,15.4,18.82,33,35.35,51.85,50.59,1.34,1.09,3.77,1.27,5.65,1.16A87,87,0,0,0,266.76,288.86Zm-38.29,9.19-38-37.2-18.14,59.63Zm50.3,10.38-.53,1.44c8.27,5.07,16.29,10.64,24.89,15.07a213.94,213.94,0,0,0,25.33,10.52,19.25,19.25,0,0,0,16-1.33c5.1-2.74,6.73-7.14,3.31-11.9a34.47,34.47,0,0,0-9.71-9c-6.86-4.21-14.8-5.19-22.74-5.76C303.08,306.53,290.89,306.5,278.77,308.43ZM176.66,198.07l1.8-.2q-.73-25.52-1.45-51c-.06-2.1.19-5-2.7-4.72-2.33.22-4.74,1.77-6.63,3.33-.93.77-1,2.87-1.05,4.39C165.85,166.79,170,182.71,176.66,198.07ZM123.16,375c-11.12,7.84-21.09,16.71-28.69,28-2.51,3.72-4.66,7.6-4.15,13.27C106,405.56,114.57,390.27,123.16,375Z"
      transform="translate(-1.64 -8.1)"
    />
  </svg>
);
Icon.displayName = "Icon.FileExt.Pdf";
export default Icon;
