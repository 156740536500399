// @flow
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Default from "../Site/Global";
import Organization from "./Organization";
import Department from "./Department";
import Shelf from "./Shelf";
import Folder from "./Folder";
import Register from "./Register";
import { type ElementTypeEnum, ElementType } from "@hs/types";

const Icon = ({
  elementType,
  ...additionalProps
}: {
  elementType: ElementTypeEnum,
  additionalProps: any,
}) => {
  const props = useMemo(
    () => ({
      ...additionalProps,
      fill: additionalProps?.fill || "rgb(119, 119, 119)",
      height: "15px",
      width: "15px",
    }),
    [additionalProps]
  );

  switch (elementType) {
    case ElementType.Organization:
      return <Organization {...props} />;
    case ElementType.Department:
      return <Department {...props} />;
    case ElementType.Shelf:
      return <Shelf {...props} />;
    case ElementType.Folder:
      return <Folder {...props} />;
    case ElementType.Register:
      return <Register {...props} />;

    case ElementType.Undefined:
    case ElementType.Root:
    default:
      return <Default {...props} />;
  }
};

Icon.propTypes = {
  elementType: PropTypes.number.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

Icon.displayName = "Icon.ElementType";
Icon.Organization = Organization;
Icon.Department = Department;
Icon.Shelf = Shelf;
Icon.Folder = Folder;
Icon.Register = Register;

export default Icon;
