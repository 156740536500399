import React from "react";
import { getClassNames } from "../data/utils";
import styles from "./Menu.module.css";

type MenuProps = {
  className?: string;
  children?: React.ReactChild | React.ReactChild[];
  setRef?: () => void;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEvent<HTMLUListElement>) => void;
};

const Menu = ({
  className,
  children,
  setRef,
  style,
  onClick,
  ...props
}: MenuProps) => (
  <ul
    className={getClassNames(className, styles.menu, "dropdown-menu")}
    ref={setRef}
    onClick={onClick}
    {...props}
    style={style}
  >
    {children}
  </ul>
);

Menu.displayName = "Dropdown.Menu";
export default Menu;
