import React from "react";

const Icon = (props: any) => (
  <svg viewBox="0 0 511 511" {...props}>
    <circle cx="242" cy="87.5" r="87.5" />
    <polygon points="479 150 347 150 242 277 133 150 2 150 170 328 0 512 131 510 242 391 345 510 477 510 312 328 479 150" />
  </svg>
);
Icon.displayName = "Icon.Site.My";
export default Icon;
