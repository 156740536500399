import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 427.85 499.41" {...props}>
    <path
      d="M0,249.76V33C0,9.41,9.36.08,32.9.08,112,.08,191.07.19,270.16,0c22.36-.05,41,7.67,56.67,23.57Q365.66,63,405.06,101.76,427.69,124,427.71,155.2q.24,157.43,0,314.85c0,19.79-10.12,29.33-30.06,29.33q-183.29,0-366.59,0c-21.46,0-31-9.42-31-31.11Q-.08,359,0,249.76Zm391.8,213.72V178.3H283.36c-25,0-33.54-8.58-33.56-33.92V36H35.86V463.49ZM285.6,142.32h105c-3.06-4.9-4.88-9.39-8-12.56q-42-42.4-84.49-84.3c-3.09-3-7.47-4.79-12.48-7.9Z"
      transform="translate(0.02 0)"
    />
    <rect x="106.38" y="222.9" width="213" height="36" rx="12" ry="12" />
    <rect x="106.38" y="293.9" width="213" height="36" rx="12" ry="12" />
    <rect x="106.38" y="365.9" width="213" height="36" rx="12" ry="12" />
  </svg>
);
Icon.displayName = "Icon.FileExt.Text";
export default Icon;
