import React from "react";

const Icon = (props: any) => (
  <svg viewBox="0 0 511 511" {...props}>
    <path
      d="M512,478H480V193H257V1H32V478H0v34H512Zm-95,0H320V415h97ZM384,225h63v63H384Zm0,95h63v63H384Zm-95-95h63v63H289Zm0,95h63v63H289Zm-161,0v63H65V320ZM65,288V225h63v63ZM96,415h97v63H96Zm65-190h63v63H161Zm0,95h63v63H161Zm0-287h63V96H161Zm0,97h63v63H161ZM65,33h63V96H65Zm0,97h63v63H65Z"
      transform="translate(0 -1)"
    />
  </svg>
);
Icon.displayName = "Icon.Site.Global";
export default Icon;
