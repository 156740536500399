// @flow
import React, { useState, useRef, useCallback, type Node } from "react";
import FormGroup from "react-bootstrap/lib/FormGroup";
import InputGroup from "react-bootstrap/lib/InputGroup";
import ClearableInput from "@hs/clearable-input";
import Icons from "@hs/icons";
import styles from "./SearchInput.module.css";
import debounce from "lodash/debounce";
import "bootstrap-no-fonts-no-js/css/bootstrap.css";
import "bootstrap-no-fonts-no-js/css/bootstrap-theme.css";

type Props = {
  /** callback when value changes
   * @param {string} value the new search value
   */
  onChange: (value: ?string) => void,
  /** what to show when search value is empty */
  placeholder?: string,
  /** controlled value to show in search input box */
  value?: string,
  /** whether the INPUT tag should immediately receive focus */
  autoFocus?: boolean,
  /** React.Node children */
  children?: Node,
  /** ref to assign to HTML input element */
  inputRef?: Object,
  /** whether to show borders */
  borderless: boolean,
  /** whether to show icon in front of input row */
  showIcon: boolean,
  /** whether to render narrow layout (icon only, input on focus) */
  narrow: boolean,
  /** whether to disable input control */
  disabled: boolean,
  /** optional icon; defaults to "search" */
  icon: string,
  /** optional CSS classname */
  className?: string,
  /** debounce timeout (in ms)
   * @default {number} 300ms
   */
  debounceTimeout?: number,
};

/**
 * Renders a controlled text input box
 */
const SearchInput = ({
  className,
  onChange,
  placeholder,
  autoFocus,
  value,
  children,
  inputRef,
  showIcon,
  icon,
  borderless,
  narrow,
  disabled,
  debounceTimeout,
}: Props) => {
  const localInputRef = useRef();
  if (inputRef == null) {
    inputRef = localInputRef;
  }
  const hasValue = value && String(value).length > 0;
  const [isFocused, setIsFocused] = useState(
    !disabled && (hasValue || !!autoFocus)
  );
  const onSearchClick = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsFocused(!disabled && true);
    inputRef && inputRef.current && inputRef.current.focus();
    return false;
  }, []);
  return (
    <FormGroup
      className={[
        className,
        styles.si,
        borderless ? styles.borderless : "",
        disabled ? styles.disabled : "",
        narrow ? styles.narrow : "",
        showIcon ? "" : styles.noIcon,
        isFocused ? styles.focused : styles.blured,
        hasValue ? "hasValue" : "",
      ]
        .filter((c) => c)
        .join(" ")}
    >
      <InputGroup>
        {(showIcon || narrow) && !isFocused && (
          <InputGroup.Addon
            className={styles.size}
            onClick={onSearchClick}
            onFocus={onSearchClick}
            title={placeholder}
          >
            <Icons.Library name={icon} />
          </InputGroup.Addon>
        )}
        {(isFocused || !narrow) && (
          <ClearableInput
            key="searchInput-txt"
            ref={inputRef}
            aria-label="search"
            placeholder={placeholder}
            onChange={debounce(onChange, debounceTimeout, {
              leading: true,
              trailing: true,
              maxWait: 700,
            })}
            onFocus={() => setIsFocused(!disabled && (hasValue || true))}
            onBlur={() => setIsFocused(!disabled && (hasValue || false))}
            autoFocus={isFocused}
            value={value}
            className="form-control"
            disabled={disabled}
          />
        )}
      </InputGroup>
      {children}
    </FormGroup>
  );
};
SearchInput.displayName = "SearchInput";
SearchInput.defaultProps = {
  showIcon: true,
  icon: "fa-solid fa-magnifying-glass",
  borderless: false,
  narrow: false,
  disabled: false,
  debounceTimeout: 300,
};
export default SearchInput;
