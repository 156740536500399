//@flow
import React, { useCallback, useEffect, useRef, useMemo } from "react";
import ClearableInput from "@hs/clearable-input";
import IMask from "imask";

type Props = {
  /* Optional Infragistics Mask String */
  mask?: string,
  /* Optional input type, defaults to text */
  inputType?: string,
  /* Optional callback on (accepted) change */
  onChange?: (value: string) => void,
  /* Optional imask definitions */
  definitions?: object,
  /* Optional imask blocks for complex/nested masks */
  blocks?: object,
  /* Optional digits after zero (when mask = Number) */
  scale?: number,
  /* Optional disallow negatives (when mask = Number) */
  signed?: boolean,
  /* Optional thousand separator (when mask = Number) */
  thousandsSeparator?: string,
  /* Optional pad zeros at the end to scale (when mask = Number) */
  padFractionalZeros?: boolean,
  /* Optional append/remove zeros at the end (when mask = Number) */
  normalizeZeros?: boolean,
  /* Optional fractional delimiter (when mask = Number) */
  radix?: string,
  /* Optional symbols to process as radix (when mask = Number) */
  mapToRadix?: Array<string>,
  /* Optional min value (when mask = Number) */
  min?: number,
  /* Optional max value (when mask = Number) */
  max?: number,
  /* autofix user input, defaults to false */
  autofix?: boolean,
  /* overwrite mask instead of placeholders, defaults to false, requires lazy to be false */
  overwrite?: boolean,
  /* hide mask placeholder, defaults to false */
  lazy?: boolean,
  /* value to return on (accepted) change, defaults to "typed" */
  unmask?: boolean | "typed",
  /* current input value */
  value?: string,
  /* enable logging to console */
  debug?: boolean,
};

/**
 * Infragistics MaskInput Syntax mapped to RegEx for IMask
 * @url https://www.infragistics.com/help/winforms/infragistics.win.ultrawingrid~infragistics.win.ultrawingrid.ultragridcolumn~maskinput
 */
const InfragisticsDefinitions = {
  "#": /[0-9]/,
  "&": /./,
  A: /[0-9a-zA-Z]/,
  a: /[0-9a-zA-Z]/,
  9: /[0-9]/,
  C: /./,
  "?": /./,
  n: /[0-9]/,
};

export default function MaskedInput({
  mask,
  inputType = "text",
  onChange,
  definitions,
  blocks,
  scale,
  signed,
  thousandsSeparator,
  padFractionalZeros = true,
  normalizeZeros = true,
  radix,
  mapToRadix,
  min,
  max,
  autofix = false,
  overwrite = false,
  lazy = false,
  unmask = false,
  value = "",
  debug = false,
  ...props
}: Props) {
  const maskRef = useRef();
  const innerRef = useRef();
  /* istanbul ignore next */
  const handleAccept = useCallback(
    (value, mask) => {
      /* istanbul ignore else */
      if (onChange) onChange({ currentTarget: { value } });
    },
    [onChange]
  );
  const targetValue = unmask === true ? "unmaskedValue" : "value";

  //Apply IMask
  /* istanbul ignore next */
  useEffect(() => {
    if (!innerRef.current || !mask) {
      return;
    }
    const maskConfig = {};
    if (mask === Number) {
      maskConfig.scale = scale || 2;
      maskConfig.signed = !!signed;
      maskConfig.padFractionalZeros = !!padFractionalZeros;
      maskConfig.normalizeZeros = !!normalizeZeros;
      maskConfig.min = min;
      maskConfig.max = max;
      maskConfig.thousandsSeparator = thousandsSeparator || "";
      maskConfig.radix = radix || ".";
      maskConfig.mapToRadix = maskConfig.mapToRadix || [maskConfig.radix];
    }
    maskRef.current = IMask(innerRef.current, {
      mask,
      lazy,
      unmask,
      autofix,
      overwrite,
      definitions: definitions || InfragisticsDefinitions,
      blocks,
      ...maskConfig,
    });
    maskRef.current.on("accept", () => {
      if (debug)
        console.log("[@hs/masked-input]", {
          reason: "accept",
          value: {
            mask: maskRef.current[targetValue],
            input: innerRef.current.value,
          },
        });
      handleAccept(maskRef.current.value);
    });
    return () => {
      maskRef.current.destroy();
      maskRef.current = null;
    };
  }, [
    mask,
    lazy,
    unmask,
    definitions,
    blocks,
    scale,
    signed,
    thousandsSeparator,
    padFractionalZeros,
    normalizeZeros,
    radix,
    mapToRadix,
    min,
    max,
    handleAccept,
    targetValue,
    autofix,
    overwrite,
  ]);
  // handle external value update
  /* istanbul ignore next */
  useEffect(() => {
    if (maskRef.current == null || innerRef.current == null) {
      return;
    }

    if (value === maskRef.current[targetValue]) {
      innerRef.current.value = maskRef.current[targetValue];
      return;
    }
    if (debug)
      console.log("[@hs/masked-input]", {
        reason: "externalUpdate",
        value: {
          prop: value,
          mask: maskRef.current[targetValue],
          input: innerRef.current.value,
        },
        input: innerRef.current,
      });
    const imaskValue = String(value);
    maskRef.current.masked[targetValue] = imaskValue;
    maskRef.current.updateControl();
    // to ensure input value equals the updated value on controlled inputs, dispatch change
    if (onChange)
      onChange({
        currentTarget: { value: maskRef.current.masked[targetValue] },
      });
  }, [value, targetValue]);

  const handleChange = useMemo(
    () => (mask ? undefined : onChange),
    [mask, onChange]
  );

  return (
    <ClearableInput
      {...props}
      inputRef={/*istanbul ignore next*/ (r) => (innerRef.current = r)}
      inputType={mask ? "text" : inputType}
      value={value}
      min={min}
      max={max}
      onChange={handleChange}
    />
  );
}
