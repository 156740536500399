import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 456 496.29" fill="#6f6f6f" {...props}>
    <path
      d="M28,256a97.06,97.06,0,0,0,175.62,57l94.56,54.59A97.09,97.09,0,1,0,397,310.48v-109a97.11,97.11,0,1,0-98.58-56.6L204,199.45A97.06,97.06,0,0,0,28,256Zm358.9-71.06a80,80,0,1,1,80-80A80,80,0,0,1,386.9,184.94ZM306.92,407a80,80,0,1,1,80,80A80,80,0,0,1,306.92,407ZM213.68,295.74a97,97,0,0,0,.25-78.93l94.6-54.62A97.12,97.12,0,0,0,377,201.55V310.45a97.13,97.13,0,0,0-68.83,39.84ZM45.11,256a80,80,0,1,1,80,80A80,80,0,0,1,45.11,256Z"
      transform="translate(-28 -7.86)"
    />
    <path d="M-107.67,36" transform="translate(-28 -7.86)" />
  </svg>
);
Icon.displayName = "Icon.Relation";
export default Icon;
