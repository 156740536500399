import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 427.81 499.41" {...props}>
    <path
      d="M1.66,257.86q0-108.37,0-216.74C1.68,17.51,11,8.18,34.54,8.18c79.09,0,158.17.11,237.26-.08,22.36-.05,41,7.67,56.67,23.57q38.83,39.38,78.23,78.19,22.63,22.21,22.65,53.44.24,157.43,0,314.85c0,19.79-10.12,29.33-30.06,29.33q-183.29.05-366.59,0c-21.46,0-31-9.42-31-31.11Q1.56,367.12,1.66,257.86Zm391.8,213.72V186.4H285c-25,0-33.54-8.58-33.56-33.92q0-49,0-98.07c0-3.41,0-6.81,0-10.29H37.5V471.59ZM287.24,150.42h105c-3.06-4.9-4.88-9.39-8-12.56q-42-42.4-84.49-84.3c-3.09-3-7.47-4.79-12.48-7.9Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      d="M114.51,222.46H150.1V258c7.41,0,13.89.11,20.37,0,10.06-.2,16.81,4.66,19.69,13.94,10.48,33.77,21.39,67.44,30.57,101.56,5.45,20.25-2.65,37.33-19.48,49.51-27.37,19.81-72.19,20-100.41,1-19.61-13.22-26.49-33.46-19.27-57.59,8.93-29.85,18.61-59.5,26.84-89.54,3.5-12.8,4.32-26.36,6-39.62C115,232.51,114.51,227.71,114.51,222.46ZM150.33,404c9.68-3.05,19.5-4.9,28.1-9.19,10.62-5.28,10.67-15.94.35-21.62-18.61-10.24-37.92-10.26-56.59-.15-10.81,5.85-10.65,16.58.55,22.08C131.11,399.23,140.64,401,150.33,404Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      d="M149.78,78.35V113H115.11V78.35Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      d="M151.08,149.1V114.34h34.61V149.1Z"
      transform="translate(-1.64 -8.1)"
    />
    <path d="M114.84,185V150.48H149.5V185Z" transform="translate(-1.64 -8.1)" />
    <path
      d="M185.7,186.42V221.2H151.07V186.42Z"
      transform="translate(-1.64 -8.1)"
    />
    <path d="M184.13,44V78.66H149.46V44Z" transform="translate(-1.64 -8.1)" />
  </svg>
);
Icon.displayName = "Icon.FileExt.Zip";
export default Icon;
