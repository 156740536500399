import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 427.81 499.41" fill="#1d7044" {...props}>
    <path
      d="M1.66,257.86q0-108.37,0-216.74C1.68,17.51,11,8.18,34.54,8.18c79.09,0,158.17.11,237.26-.08,22.36-.05,41,7.67,56.67,23.57q38.83,39.38,78.23,78.19,22.63,22.21,22.65,53.44.24,157.43,0,314.85c0,19.79-10.12,29.33-30.06,29.33q-183.29.05-366.59,0c-21.46,0-31-9.42-31-31.11Q1.56,367.12,1.66,257.86Zm391.8,213.72V186.4H285c-25,0-33.54-8.58-33.56-33.92q0-49,0-98.07c0-3.41,0-6.81,0-10.29H37.5V471.59ZM287.24,150.42h105c-3.06-4.9-4.88-9.39-8-12.56q-42-42.4-84.49-84.3c-3.09-3-7.47-4.79-12.48-7.9Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      d="M232.35,437.43V409.79l20-1.09-35.49-53.45-34,53.41,19.46,1.13v27.62H127V408.6c18.95,6.5,23.94-8.41,31.5-19.33,12-17.28,23.58-34.81,35.7-52,3-4.29,3.1-7.17,0-11.5q-21.84-30.67-42.93-61.87c-3.52-5.22-7.36-7.75-13.61-6.9-2.83.39-5.77.07-9.42.07V228.4h78.11v28.51H185.94l35.66,52.93,33.85-52.27h-19.2V228.33H311v27.41c-.85.51-1.4,1.13-1.82,1-13-2.49-19.75,4.72-26.22,14.9-12.37,19.44-25.91,38.14-39.27,56.93-2.83,4-3,6.71-.14,10.75C259,361,274,382.9,289.6,404.42c1.82,2.52,6,3.92,9.29,4.66,3.57.79,7.44.18,11.72.18v28.17Z"
      transform="translate(-1.64 -8.1)"
    />
  </svg>
);
Icon.displayName = "Icon.FileExt.Excel";
export default Icon;
