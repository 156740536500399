import React from "react";

const Icon = (props: any) => (
  <svg viewBox="0 0 511 511" {...props}>
    <polygon points="31.51 0 481.67 0 256.59 156.43 31.51 0" />
    <polygon points="0 33.76 109.16 111.42 0 195.47 0 33.76" />
    <polygon points="512.06 33.76 402.89 111.42 512.06 195.47 512.06 33.76" />
    <polygon points="0 253.22 148.94 139.55 257.72 212.7 361.78 139.55 512.06 252.2 512.06 350 0 350 0 253.22" />
  </svg>
);
Icon.displayName = "Icon.Site.Mail";
export default Icon;
