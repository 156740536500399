//@flow
import React from "react";
import Library from "./Library";
import { type OfflineSyncStatusEnum, OfflineSyncStatus } from "@hs/types";
import loc from "i18next";

const Icon = ({ status }: { status: OfflineSyncStatusEnum }) => {
  switch (status) {
    case OfflineSyncStatus.Synced:
      return (
        <Library
          title={loc.t(`offline_item:syncStatus.${status}`)}
          name="fa-solid fa-check fa-fw"
          style={{ color: "green" }}
          
        />
      );
    case OfflineSyncStatus.SyncedWithErrors:
      return (
        <Library
          title={loc.t(`offline_item:syncStatus.${status}`)}
          name="fa-solid fa-warning fa-fw"
          style={{ color: "orange" }}
        />
      );
    case OfflineSyncStatus.Syncing:
      return (
        <Library
          title={loc.t(`offline_item:syncStatus.${status}`)}
          name="fa-solid fa-spinner fa-pulse fa-fw"
          style={{ color: "grey" }}
        />
      );
    case OfflineSyncStatus.Error:
      return (
        <Library
          title={loc.t(`offline_item:syncStatus.${status}`)}
          name="fa-solid fa-circle-minus fa-fw"
          style={{ color: "red" }}
        />
      );
    default:
      return (
        <Library
          title={loc.t(`offline_item:syncStatus.Unknown`)}
          name="fa-regular fa-hourglass-start fa-fw"
          style={{ color: "grey" }}
        />
      );
  }
};
Icon.displayName = "Icon.SyncStatus";
export default Icon;
