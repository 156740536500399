// @flow
import React, { useState, useEffect, useRef } from "react";
import Icons from "@hs/icons";
import { useMemo, useCallback } from "react";
import useScrollHandler from "./hooks";

type Props = {
  /** Path to the HTML file*/
  url?: string,
  /** Fetch function to use to get the html */
  handleFetch?: Function,
  /** HTML to display*/
  html?: string,
  /** CSS class to apply to top container */
  className?: string,
  /** additional css styling */
  style?: CSStyleDefinition,
  /** whether to show error when the html and url is inavlid or to fail silently */
  showIfEmpty?: boolean,
  /** whether the html file should be scrolled to the end on loading */
  atEnd?: boolean,
};

const HtmlViewerAction = {
  Top: "html.viewer.top",
  Bottom: "html.viewer.bottom",
};

// From https://reactjs.org/docs/hooks-state.html
const HtmlViewer = ({
  html: htmlFromProps,
  handleFetch: handleFetchFromProps,
  url,
  className,
  style,
  showIfEmpty,
  atEnd,
}: Props) => {
  const [html, setHtml] = useState(htmlFromProps);
  const [loading, setLoading] = useState(false);
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  // isFirstScroll is necessary, so the default "scroll to the bottom" doesn't trigger the HtmlViewerAction
  const [isFirstScroll, setIsFirstScroll] = useState(true);
  const iframeRef = useRef(null);

  const scrollHandler = useScrollHandler(
    isFirstScroll,
    setIsFirstScroll,
    HtmlViewerAction
  );

  const defaultFetch = useCallback(async (url) => {
    const response = await fetch(url);
    const text = await response.text();
    return text;
  }, []);

  const handleFetch = useMemo(
    () =>
      typeof handleFetchFromProps === "function"
        ? handleFetchFromProps
        : defaultFetch,
    [handleFetchFromProps]
  );

  useEffect(() => {
    if (url) {
      setLoading(true);

      (async function () {
        try {
          const text = await handleFetch(url);
          setHtml(text);
          setLoading(false);
        } catch (e) {
          setHtml(htmlFromProps);
          setLoading(false);
        }
      })();
    } else {
      setHtml(htmlFromProps);
    }
  }, [url, htmlFromProps]);

  useEffect(() => {
    if (!isIframeLoaded) {
      return;
    }

    let contentWindow = iframeRef.current?.contentWindow

    contentWindow?.addEventListener("scroll", scrollHandler);

    return () =>
    contentWindow?.removeEventListener(
        "scroll",
        scrollHandler
      );
  }, [isIframeLoaded, scrollHandler]);

  const wrapperStyle = useMemo(
    () => ({
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      ...style,
    }),
    [style]
  );

  const iframeLoadedHandler = () => {
    const contentWindow = iframeRef.current.contentWindow;

    if (atEnd) {
      contentWindow.scrollTo({
        top: contentWindow.document.documentElement.scrollHeight,
      });
    }

    setIsIframeLoaded(true);
  };

  if (loading || !html) {
    return (
      <div className={className} style={wrapperStyle}>
        {loading ? (
          <Icons.Spinner pulse className="fa-lg" />
        ) : (
          !showIfEmpty && <Icons.Error className="fa-lg" />
        )}
      </div>
    );
  }

  return (
    <iframe
      className={className}
      style={style}
      srcDoc={html}
      frameBorder="0"
      onLoad={iframeLoadedHandler}
      ref={iframeRef}
    ></iframe>
  );
};

HtmlViewer.displayName = "HtmlViewer";

export default HtmlViewer;

export { HtmlViewerAction };
