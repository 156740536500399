// @flow
import React, { PureComponent } from "react";
import getLineHeight from "line-height";
import styles from "./ClearableInput.module.css";

// refactored from https://github.com/kanatzidis/rc-clearable-input

type Props = {
  /** Optional ref for input/textarea*/
  inputRef?: (el: null | HTMLInputElement | HTMLTextareaElement) => void,
  /** input type=xxx
   * @default text
   */
  inputType?: string,
  /** CSS Classname to assign to the INPUT tag */
  className?: string,
  /** placeholder text to display when @see {value} is empty */
  placeholder?: string,
  /** callback when input value changes
   * @param {string} newValue new input value
   */
  onChange?: (
    valueOrEvent: string | SyntheticInputEvent<*> | SyntheticTextareaEvent<*>
  ) => void,
  /** callback when input focuses
   * @param {SyntheticInputEvent<*>} event focus event
   */
  onFocus?: (event: SyntheticInputEvent<*> | SyntheticTextareaEvent<*>) => void,
  /** callback when input blurs
   * @param {SyntheticInputEvent<*>} event blur event
   */
  onBlur?: (event: SyntheticInputEvent<*> | SyntheticTextareaEvent<*>) => void,
  /** whether onChange should be called with raw event or straight with value
   * @default false
   */
  onChangeWithRawEvent?: boolean,
  /** callback when user presses Enter in the input field
   * @param {string} value current input value
   */

  onEnter?: (value: string) => void,
  /** CSS Style to apply */
  style?: Object,
  /** pre-defined input value to show */
  value?: string,
  /** whether the INPUT tag should immediately receive focus */
  autoFocus?: boolean,
  /** whether the user must enter a value */
  required?: boolean,
  /** whether to disable input control */
  disabled?: boolean,
  /** whether the control is rendered as textarea; ignores inputType */
  multi?: boolean,
  /** min rows */
  minRows?: number,
  /** max rows */
  maxRows?: number,
  /** id of the input */
  id?: string,
  /** callback when user clicks the X to clear the input */
  onClear?: () => void,
};

type State = {
  value: string,
  onX: boolean,
  hasScrollbar: boolean,
};

/* istanbul ignore next */
const requestBrowserRepaint = window.requestAnimationFrame || window.setTimeout;

/**
 * Will display an input box to the user with an animated X to clear it's contents.
 */
export default class ClearableInput extends PureComponent<Props, State> {
  _ref: HTMLInputElement | HTMLTextareaElement | null;

  constructor(props: Props, children: any) {
    super(props);
    this.state = {
      value: props.value ? props.value : "",
      onX: false,
      hasScrollbar: false,
    };
  }

  _inputRef = (ref: HTMLInputElement | HTMLTextareaElement | null) => {
    this._ref = ref;
    if (this.props.inputRef) this.props.inputRef(ref);
  };

  componentDidMount() {
    if (this.props.autoFocus === true && this._ref != null) {
      this._ref.select();
    }

    if (this.props.multi) {
      this.resizeTextarea();
    }
  }

  componentDidUpdate(prevProps: Props): void {
    let resizeComponent = false;
    /* istanbul ignore else */
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
      resizeComponent = this.props.multi;
    }
    /* istanbul ignore next */
    if (prevProps.multi !== this.props.multi || resizeComponent) {
      requestBrowserRepaint(() => {
        if (this.props.multi) {
          this.resizeTextarea();
        } else if (this._ref != null) {
          this._ref.style.removeProperty("height");
        }
      });
    }
  }

  getValue = (): string => this.state.value;

  handleChange = (e: SyntheticInputEvent<*>) => {
    this.setState({ value: e.currentTarget.value });
    /* istanbul ignore else */
    if (typeof this.props.onChange === "function") {
      if (this.props.onChangeWithRawEvent === true) {
        this.props.onChange(e);
      } else {
        this.props.onChange(e.currentTarget.value);
      }
    }
  };

  onKeyDown = (e: SyntheticKeyboardEvent<*>) => {
    //Prevent
    if (this.props.multi && e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      this.onKeyUp(e);
      return false;
    }
  };

  onKeyUp = (e: SyntheticKeyboardEvent<*>) => {
    // Enter (but not with Shift)
    const isEnterNotShiftEnter = e.keyCode === 13 && !e.shiftKey;

    // On Escape or Custom Enter
    /* istanbul ignore next */
    if (e.keyCode === 27 || isEnterNotShiftEnter) {
      if (this._ref != null) this._ref.blur();
    }
    if (isEnterNotShiftEnter && typeof this.props.onEnter === "function") {
      this.props.onEnter(this.state.value);
    }
  };

  /* istanbul ignore next */ handleTextareaChange = (
    e: SyntheticInputEvent<*> | SyntheticTextareaEvent<*>
  ) => {
    this.resizeTextarea();
    this.handleChange(e);
  };

  /* istanbul ignore next*/ resizeTextarea = () => {
    if (this._ref == null) return;
    const { minRows, maxRows } = this.props;

    const computedStyles = window.getComputedStyle(this._ref);
    const border =
      parseInt(computedStyles.getPropertyValue("border-top-width"), 10) +
      parseInt(computedStyles.getPropertyValue("border-bottom-width"), 10);
    const padding =
      parseInt(computedStyles.getPropertyValue("padding-top"), 10) +
      parseInt(computedStyles.getPropertyValue("padding-bottom"), 10);
    const lineHeight = getLineHeight(this._ref);
    const minHeight =
      (minRows > 1 ? lineHeight * minRows : lineHeight) + border;
    const maxHeight = maxRows ? lineHeight * maxRows + padding : -1;

    let hasScrollbar = false;
    this._ref.style.height = "0px";
    let contentHeight = this._ref.scrollHeight + border;
    if (contentHeight < minHeight) {
      contentHeight = minHeight;
    } else if (maxHeight > -1 && contentHeight > maxHeight) {
      hasScrollbar = true;
      this._ref.scrollTop = contentHeight;
      contentHeight = maxHeight;
    }
    this._ref.style.height = `${contentHeight}px`;
    this.setState(() => ({
      hasScrollbar,
    }));
  };

  onClick = (e: SyntheticMouseEvent<*>) => {
    if (!this.state.onX) return;
    e.preventDefault();
    this.setState({
      onX: false,
    });
    e.currentTarget.value = "";
    if (typeof this.props.onClear == "function") {
      this.props.onClear();
    }
    const event = new Event("input", { bubbles: true });
    this._ref.dispatchEvent(event);
    if (this.props.multi) {
      this.handleTextareaChange(e);
    } else {
      this.handleChange(e);
    }
  };

  /* istanbul ignore next */ onMouseMove = (e: SyntheticMouseEvent<*>) => {
    e.persist();
    if (!this.state.value) return;
    if (this._ref === null) return;
    const leftBounding =
      this._ref.offsetWidth - (this.state.hasScrollbar ? 41 : 25);
    const rightBounding =
      this._ref.offsetWidth - (this.state.hasScrollbar ? 21 : 10);
    const posX = e.clientX - this._ref.getBoundingClientRect().left;
    this.setState({
      onX: leftBounding < posX && rightBounding > posX,
    });
  };

  render = () => {
    const {
      multi,
      inputType = "text",
      disabled,
      placeholder = "",
      className,
      minRows = 1,
      style = {},
      inputRef,
      maxRows,
      onChangeWithRawEvent,
      onEnter,
      id,
      onClear,
      ...props
    } = this.props;

    const Component = multi ? "textarea" : "input";

    return (
      <Component
        {...props}
        type={multi ? undefined : inputType}
        disabled={disabled}
        placeholder={placeholder}
        className={[
          className,
          styles.clearable,
          this.state.value ? styles.x : "",
          this.state.onX ? styles.onX : "",
          multi ? styles.multi : "",
          /* istanbul ignore next */ this.state.hasScrollbar
            ? styles.hasScrollbar
            : "",
          disabled ? styles.disabled : "",
        ]
          .filter((c) => c)
          .join(" ")}
        onChange={multi ? this.handleTextareaChange : this.handleChange}
        onMouseMove={this.onMouseMove}
        onTouchStart={this.onClick}
        onClick={this.onClick}
        onKeyUp={this.onKeyUp}
        onKeyDown={this.onKeyDown}
        onMouseUp={multi ? this.resizeTextarea : undefined}
        ref={this._inputRef}
        style={style}
        disabled={disabled}
        rows={multi ? minRows : undefined}
        value={this.state.value || ""}
        id={id}
      />
    );
  };
}
