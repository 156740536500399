import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 273.6 473.08" {...props}>
    <path
      fill="#6f6f6f"
      d="M-107.67,36"
      transform="translate(-118.45 -19.95)"
    />
    <path
      fill="#4d82b8"
      d="M207.62,108.47,119.47,21,391,19.95l1.05,271.53L302,202.08C140.91,357,259.2,460.83,372.37,493,165.83,485.8,14.27,293.43,207.62,108.47Z"
      transform="translate(-118.45 -19.95)"
    />
  </svg>
);
Icon.displayName = "Icon.Link";
export default Icon;
