import { useLayoutEffect, useState, useMemo } from "react";

type IntersectionObserverProps = {
  root?: Element;
  rootMargin: string;
  threshold: number | Array<number>;
};

function useIntersectionObserver<T extends Element>({
  root,
  rootMargin,
  threshold,
}: IntersectionObserverProps) {
  const options = useMemo<IntersectionObserverProps>(
    () => ({ root, rootMargin, threshold }),
    [root, rootMargin, threshold]
  );
  const [ref, setRef] = useState<T | null>(null);
  const [entry, setEntry] = useState<IntersectionObserverEntry>(
    {} as IntersectionObserverEntry
  );
  useLayoutEffect(() => {
    if (ref == null) return;
    const observer = new IntersectionObserver((entries) => {
      setEntry(entries[0]);
    }, options);
    observer.observe(ref);
    return () => {
      observer.disconnect();
    };
  }, [options, ref]);
  return { setRef, entry };
}

export default useIntersectionObserver;
