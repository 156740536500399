import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 479.76 479.87" fill="#6f6f6f" {...props}>
    <path
      d="M16.12,495.94V76.41h313.2c-.66.82-1,1.39-1.51,1.86Q300.12,106,272.45,133.72a7.93,7.93,0,0,1-6.2,2.55q-92.62-.12-185.24-.06H76.25V436h299.7c0-1.41,0-2.75,0-4.08q0-93,.08-186a8.15,8.15,0,0,1,1.79-5.28q27.94-28.26,56.14-56.27c.43-.43.91-.79,1.72-1.5V495.94Z"
      transform="translate(-16.12 -16.06)"
    />
    <path
      d="M397.86,49.7c9.9-9,18.93-19.16,29.83-26.6,25.72-17.54,62.78-.38,67.55,30.42,2.39,15.41-2,28.62-12.77,39.76-6.76,7-13.64,13.88-20.23,20.57Z"
      transform="translate(-16.12 -16.06)"
    />
    <polygon points="360.58 55.4 424.58 120.4 239.58 304.4 176.58 241.4 360.58 55.4" />
    <polygon points="154.58 259.4 219.58 323.4 118.58 360.4 154.58 259.4" />
  </svg>
);
Icon.displayName = "Icon.Notes";
export default Icon;
