import Attachment from "./Attachment";
import Forwarded from "./Forwarded";
import Replied from "./Replied";

const EmailIcons = {
    Attachment,
    Forwarded,
    Replied,
};
export default EmailIcons;
