// @flow
import React, { useRef, useEffect, type Node } from "react";
import MarkJs from "front-markjs";

const Accuracy = {
  /** When searching for "lor" only "lor" inside "lorem" will be marked */
  partially: "partially",
  /** When searching for "lor" the whole word "lorem" will be marked */
  complementary: "complementary",
  /** When searching for "lor" only those exact words with a word boundary will be marked. In this example nothing inside "lorem". This value is equivalent to the previous option wordBoundary */
  exactly: "exactly",
};

type AccuracyEnum = $Keys<typeof Accuracy>;

type AccuracyValue = {
  value: AccuracyEnum,
  limiters: Array<string>,
};

type Props = {
  /** CSS Styles to apply */
  style?: Object,
  /** the word(s) to highlight in the children */
  word?: string,
  /** the content to highlight */
  children: Node,
  /** HTML class attribute*/
  className?: string,
  /** mark.js options */
  options: {
    className?: string,
    /** A class name that will be appended to element */
    className?: string,
    /** An array with exclusion selectors. Matches inside these elements will be ignored.
     * @example "filter": ["h1", ".ignore"]
     */
    exclude?: Array<string>,
    /** Whether to search for each word separated by a blank instead of the complete term */
    separateWordSearch?: boolean,
    /** Either one of the following string values:
     * "partially": When searching for "lor" only "lor" inside "lorem" will be marked
     * "complementary": When searching for "lor" the whole word "lorem" will be marked
     * "exactly": When searching for "lor" only those exact words with a word boundary will be marked. In this example nothing inside "lorem". This value is equivalent to the previous option wordBoundary
     * Or an object containing two properties:
     * "value": One of the above named string value
     * "limiters": A custom array of string limiters for accuracy "exactly" or "complementary". */
    accuracy?: AccuracyEnum | AccuracyValue,
    /** If diacritic characters should be matched. For example "piękny" would also match "piekny" and "doner" would also match "döner" */
    diacritics?: boolean,
    /** An object with synonyms. The key will be a synonym for the value and the value for the key.
     * @example "synonyms": {"one": "1"} will add the synonym "1" for "one" and vice versa */
    synonyms?: Object,
    /** Whether to search also inside iframes. If you don't have permissions to some iframes (e.g. because they have a different origin) they will be silently skipped. If you don't want to search inside specific iframes (e.g. facebook share), you can pass an exclude selector that matches these iframes */
    iframes?: boolean,
    /** Whether to search for matches across elements */
    acrossElements?: boolean,
    /** Whether to search case sensitive */
    caseSensitive?: boolean,
    /** Whether to also find matches that contain soft hyphen, zero width space, zero width non-joiner and zero width joiner. They're used to indicate a point for a line break where there isn't enough space to show the full word */
    ignoreJoiners?: boolean,
    /** A callback for each marked element.
     * @param {HTMLElement} elem the marked DOM element */
    each?: (elem: HTMLElement) => void,
    /** A callback to filter or limit matches. It will be called for each match and receives the following parameters:
     * @param {HTMLElement} textNode The text node which includes the match
     * @param {string} term The term that has been found
     * @param {number} totalMarks A counter indicating the total number of all marks at the time of the function call
     * @param {number} termMark A counter indicating the number of marks for the term
     * @returns The function must return false if the mark should be stopped, otherwise true */
    filter?: (
      textNode: HTMLElement,
      term: string,
      totalMarks: number,
      termMark: number
    ) => boolean,
    /** A callback function that will be called when there are no matches.
     * @param {string} term the not found term */
    noMatch?: (term: string) => void,
    /** A callback function after all marks are done.
     * @param {number} totalMarks the total number of marks */
    done?: (totalMarks: number) => void,
    /** Set this option to true if you want to log messages */
    debug?: boolean,
    /** Log messages to a specific object (only if debug is true) */
    log?: Object,
  },
};

/**
 * wrapper for MarkJs, refactored from https://github.com/askbeka/react-front-mark
 *
 * @export
 */
const Mark = (props: Props) => {
  const { word, children, style, className, options = {} } = props;
  const mark = useRef(null);
  useEffect(() => {
    /* istanbul ignore if */
    if (mark.current == null) return;
    const instance = new MarkJs(mark.current);
    instance.mark(
      word || /* istanbul ignore next */ "",
      Object.assign(
        {},
        {
          style: {},
          element: "mark",
          className: "",
          exclude: [],
          separateWordSearch: true,
          accuracy: "partially",
          diacritics: true,
          synonyms: {},
          iframes: false,
          acrossElements: true,
          caseSensitive: false,
          ignoreJoiners: false,
          debug: false,
          log: window.console,
        },
        options
      )
    );
    return () => {
      instance.unmark();
    };
  }, [word, options]);
  return (
    <span className={className} style={style} ref={mark}>
      {children}
    </span>
  );
};

export default Mark;
