import React from "react";

const Icon = (props: any) => (
  <svg viewBox="0 0 511 511" {...props}>
    <path
      d="M418.28,323a93.66,93.66,0,0,0-63.69,24.88L189.44,266.22A95,95,0,0,0,190,256c0-2.82-.13-5.6-.37-8.35l165.46-81.81A94,94,0,1,0,324,96q0,3.56.26,7.06L157.94,185.3a94,94,0,1,0-1.45,142.65l168,83.06c-.12,2-.2,4-.2,6a94,94,0,1,0,94-94Z"
      transform="translate(-2 -2)"
    />
  </svg>
);
Icon.displayName = "Icon.Site.Shares";
export default Icon;
