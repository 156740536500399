import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 427.81 499.41" fill="#93278f" {...props}>
    <path
      d="M1.66,257.86q0-108.37,0-216.74C1.68,17.51,11,8.18,34.54,8.18c79.09,0,158.17.11,237.26-.08,22.36-.05,41,7.67,56.67,23.57q38.83,39.38,78.23,78.19,22.63,22.21,22.65,53.44.24,157.43,0,314.85c0,19.79-10.12,29.33-30.06,29.33q-183.29.05-366.59,0c-21.46,0-31-9.42-31-31.11Q1.56,367.12,1.66,257.86Zm391.8,213.72V186.4H285c-25,0-33.54-8.58-33.56-33.92q0-49,0-98.07c0-3.41,0-6.81,0-10.29H37.5V471.59ZM287.24,150.42h105c-3.06-4.9-4.88-9.39-8-12.56q-42-42.4-84.49-84.3c-3.09-3-7.47-4.79-12.48-7.9Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      d="M192.23,332.84c0,21.77.15,43.55-.1,65.32-.07,5.72,2.53,13.09-5,16.09-7.1,2.82-11.09-3.13-15.34-7.36-11.53-11.46-22.76-23.24-34.63-34.34a21.41,21.41,0,0,0-12-5c-8.35-.76-16.82-.33-25.24-.2-7.74.12-12-3-12-11.25.14-15.68.1-31.36,0-47,0-8,3.53-11.94,11.69-11.3.58,0,1.18-.09,1.74,0,22.46,4.1,39.56-4,53.09-22.06,5.68-7.59,13-14.19,20.3-20.27,3.3-2.73,8.76-4.49,12.85-3.91,2,.28,4.29,6.94,4.35,10.74C192.43,285.8,192.23,309.32,192.23,332.84Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      d="M350,332.47c-.75,36.61-12.35,69-34.91,97.49-6.3,8-16.41,10.08-23.77,5-8.43-5.84-10.61-16.39-4-25.24,11.4-15.32,20.63-31.6,24.34-50.46,7.18-36.56.92-70.32-22-100.32-5.46-7.14-8.82-14.68-3.63-23.16,6.8-11.12,20.32-11.37,29.16-.53C329.66,253,340,273.06,344.78,295.54,347.36,307.67,348.31,320.15,350,332.47Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      d="M279.24,331.92c.26,22.69-7.13,41.72-21.59,58-7.7,8.66-17.74,9.95-25.34,3.73-7.81-6.4-8.84-16-1.41-25.39,14.69-18.58,18.41-38.29,7.7-59.86-2.29-4.62-5.85-8.59-8.71-12.94-5.69-8.67-4.59-18.55,2.6-24s17.53-4.58,24.31,2.83C271.94,290.73,279.66,310.22,279.24,331.92Z"
      transform="translate(-1.64 -8.1)"
    />
  </svg>
);
Icon.displayName = "Icon.FileExt.Audio";
export default Icon;
