//@flow
import React from "react";

const Icon = (props: any) => (
  <svg viewBox="0 0 501 505" {...props}>
    <path
      d="M476.67,3.5H35.33A29.83,29.83,0,0,0,5.5,33.33V248.5h501V33.33A29.83,29.83,0,0,0,476.67,3.5ZM325.5,112.5h-140v-70h25v45h91.67v-45H325.5Z"
      transform="translate(-5.5 -3.5)"
    />
    <path
      d="M5.5,478.67A29.83,29.83,0,0,0,35.33,508.5H476.67a29.83,29.83,0,0,0,29.83-29.83V263.5H5.5Zm180-173.17h25v45h91.67v-45H325.5v70h-140Z"
      transform="translate(-5.5 -3.5)"
    />
    <polygon points="296.67 84 205 84 205 39 180 39 180 109 320 109 320 39 296.67 39 296.67 84" />
    <polygon points="320 302 296.67 302 296.67 347 205 347 205 302 180 302 180 372 320 372 320 302" />
  </svg>
);
Icon.displayName = "Icon.ElementType.Shelf";
export default Icon;
