import { BasePlugin } from "@uppy/core";
import isEmpty from "lodash/isEmpty";
import "./msg.reader.js";

export default class UppyMsg extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.uppy = uppy;
    this.type = "msg";
    this.id = "Msg";
    this.title = "uppy-msg";

    // set default options
    const defaultOptions = {
      prefix: "msg_",
      tags: [],
    };

    // merge default options with the ones set by user
    this.opts = Object.assign({}, defaultOptions, opts);
  }

  install() {
    this.uppy.log("[Msg] Installing UppyMsg Plugin");
    this.uppy.on("file-added", this.extractMsg);
  }

  uninstall() {
    this.uppy.log("[Msg] Removing UppyMsg Plugin");
    this.uppy.off("file-added", this.extractMsg);
  }

  getSenderDomain = (fileData) => {
    const senderEmail = fileData && fileData["senderEmail"];
    return senderEmail
      ? senderEmail.substring(senderEmail.lastIndexOf("@") + 1)
      : null;
  };

  extractMsg = (file) => {
    if (!file.meta.name.toLowerCase().endsWith(".msg")) {
      this.uppy.log("[Msg] only .msg files are supported");
      return;
    }

    this.uppy.log(`[Msg] Parsing header from ${file.name}`);
    const reader = new FileReader();
    reader.onload = (evt) => {
      // DONE?
      if (reader.readyState === 2) {
        try {
          var buffer = evt.target.result;
          var msgReader = new window.MSGReader(buffer);
          var fileData = msgReader.getFileData();
          let metadata = {};
          if (!fileData.error) {
            // any requested tags?
            if (this.opts.tags && this.opts.tags.length > 0) {
              // yes, return only those
              this.opts.tags.forEach((tagName) => {
                const tag = fileData[tagName];
                if (tag != null) {
                  metadata[this.opts.prefix + tagName] = tag;
                } else {
                  // custom calculated tags
                  if (tagName === "senderDomain") {
                    metadata[
                      this.opts.prefix + "senderDomain"
                    ] = this.getSenderDomain(fileData);
                  }
                }
              });
            } else {
              // no, return them all
              Object.keys(fileData).forEach((tagName) => {
                metadata[this.opts.prefix + tagName] = fileData[tagName];
              });
              metadata[
                this.opts.prefix + "senderDomain"
              ] = this.getSenderDomain(fileData);
            }
          }
          // console.log(metadata);

          // any tags found?
          if (!isEmpty(metadata)) {
            this.uppy.setFileMeta(file.id, metadata);
            this.uppy.log(
              `[Msg] Found tags for ${file.name} and added to metadata with prefix: ${this.opts.prefix}`
            );
          } else {
            this.uppy.log(
              `[Msg] None of the requested tags found for ${file.name}`
            );
          }
        } catch (err) {
          console.log(err);
          // got invalid data, handle error
          this.uppy.log(
            `[Msg] Cannot parse header for ${file.name}`,
            err.message
          );
        }
      } else {
        this.uppy.log(
          `[Msg] Cannot load file ${file.name}: ${reader.error.message}`
        );
      }
    };

    // convert from Blob to ArrayBuffer
    reader.readAsArrayBuffer(file.data);
  };
}
