// @flow
import React, { PureComponent, type Node } from "react";
import MultiGrid from "react-virtualized/dist/commonjs/MultiGrid";
import Measure from "react-measure";
import CellHeader from "./CellHeader";
import CellColumn from "./CellColumn";
import styles from "./GridList.module.css";
import isEqual from "lodash/isEqual";
import { type SortDirectionEnum } from "@hs/types";

const STYLE = {
  marginLeft: 0,
  marginTop: 0,
};
const STYLE_BOTTOM_LEFT_GRID = {
  borderRight: "2px solid #aaa",
};
const STYLE_TOP_LEFT_GRID = {
  borderBottom: "2px solid #aaa",
  borderRight: "2px solid #aaa",
  fontWeight: "bold",
};
const STYLE_TOP_RIGHT_GRID = {
  borderBottom: "1px solid #cccccc",
  fontWeight: "bold",
  background: "#f5f5f5",
};

type Props = {
  /** class name */
  className: ?string,

  /** Column Array */
  cols: Array<any>,

  /** Rows Array */
  rows: Array<any>,

  /** callback to request loading next page */
  loadMoreRows: ({ rowIndex: number }) => Promise<Array<any>>,

  /** which row index is currently selected */
  activeRowIndex?: ?number,

  /** which row index to bring into view */
  scrollToRow?: ?number,

  /** which row is the UI currently updating
   * @param {number} rowStartIndex row index in result.rows
   */
  rowStartIndexUpdated: (rowStartIndex: number) => void,

  /** callback when user single clicks the whole row
   * @param {SyntheticMouseEvent} e HTML click event
   * @param {number} rowIndex the row's index
   * @param {number} columnIndex the column's index
   * @param {string} itemUri the row's itemUri
   */
  onCellClick: (
    e: SyntheticMouseEvent<*>,
    rowIndex: number,
    columnIndex: number,
    itemUri: string
  ) => void,

  /** callback when user double clicks the whole row
   * @param {SyntheticMouseEvent} e HTML click event
   * @param {string} itemUri the row's itemUri
   * @param {number} rowIndex the row's index
   */
  onCellDoubleClick?: (
    e: SyntheticMouseEvent<*>,
    itemUri: string,
    rowIndex: number
  ) => void,

  /** callback when user clicks on More... button
   * @param {SyntheticMouseEvent} e HTML click event
   * @param {string} itemUri the row's itemUri
   * @param {number} rowIndex the row's index
   */
  onCellMoreClick?: (
    e: SyntheticMouseEvent<*>,
    itemUri: string,
    rowIndex: number
  ) => void,

  /** whether to disable the More... button */
  actionsDisabled?: boolean,

  /** callback when user right clicks the whole row
   * @param {SyntheticMouseEvent} e HTML click event
   * @param {number} rowIndex the row's index
   * @param {number} columnsIndex the column's index
   * @param {string} itemUri the row's itemUri
   */
  onContextClick?: (
    e: SyntheticMouseEvent<*>,
    rowIndex: number,
    columnIndex: number,
    itemUri: string
  ) => void,

  /** React.Node children */
  children?: Node,

  /** check if column can be filtered
   * @param {string} name column's common name
   * @return {bool} whether column can be filtered
   */
  canColumnFilter: (name: string) => boolean,

  /** check if column is filtered
   * @param {string} name column's common name
   * @return {bool} whether column is filtered
   */
  isColumnFiltered: (name: string) => boolean,

  /** check if column is sorted
   * @param {string} name column's common name
   * @return {bool} whether column is sorted
   */
  isColumnSorted: (name: string) => boolean,

  /** get column's sort direction
   * @param {string} name column's common name
   * @return {SortDirectionEnum} column's sort direction
   */
  getColumnSortDirection: (name: string) => SortDirectionEnum,

  /** get cell column title
   * @param {string} caption column's raw caption
   * @param {string} content column's raw content
   * @return {?string} parsed column's title
   */
  parseCellColumnTitle: (caption: string, content: any) => string,

  /** callback when user clicks on header
   * @param {number} columnIndex: number,
   * @param {string} rawData column's header caption
   */
  onHeaderClick: (columnIndex: number, rawData: string) => void,

  /** callback when user clicks on filter icon (header)
   * @param {SyntheticMouseEvent<*>} event mouse event
   * @param {string} rawData column's title
   */
  onHeaderFilterClick: (event: SyntheticMouseEvent<*>, name: string) => void,

  /** Title for Filter Icon in HeaderColumn */
  headerFilterTitle: ?string,

  /** Filter text to highlight */
  filterText: ?string,

  /** parse cell header caption
   * @param {string} rawData column's header caption
   * @return {string} parsed column's header caption
   */
  parseCellHeaderCaption: (rawData: string) => string,

  /** check if row is Loaded
   * @param {number} {rowIndex} row's index
   * @return {bool} whether row is loaded
   */
  isRowLoaded: (props: { rowIndex: number }) => boolean,

  /** check if row is active
   * @param {number} {rowIndex} row's index
   * @param {bool} {defaultState} row's UI active state
   * @return {bool} whether row is active
   */
  isRowActive?: (rowIndex: number, defaultState: boolean) => boolean,

  /** optional cell getter
   * @param {any} {row} row object/array
   * @param {number} {columnIndex} column's index
   * @default row[columnIndex] if the row is a simple array or object with cell index, it will return value by index
   * @return {any} whether row is loaded
   */
  getCell?: (props: { row: any, columnIndex: number }) => any,

  /** CellContent Component */
  CellContent: any,

  /** ContextMenuHandler Component */
  ContextMenuHandler: any,
};

type State = {
  hoveredRowIndex: number,
  activeRowIndex: number,
};

/**
 * Renders the Grid documents list
 */
class GridList extends PureComponent<Props, State> {
  multiGrid: MultiGrid; // internal ref to rendered virtualized List Component
  _onRowsRendered: Function;

  constructor(props: Props) {
    super(props);
    this.state = {
      hoveredRowIndex: -1,
      activeRowIndex: props.activeRowIndex != null ? props.activeRowIndex : -1,
    };
  }

  componentDidUpdate(prevProps: Props) {
    /* istanbul ignore else */
    if (
      this.props.activeRowIndex != null &&
      this.props.activeRowIndex !== this.state.activeRowIndex
    ) {
      this.setState({ activeRowIndex: this.props.activeRowIndex });
      this.multiGrid.forceUpdateGrids();
    }
    /* istanbul ignore if */
    if (this.props.filterText !== prevProps.filterText) {
      this.multiGrid.forceUpdateGrids();
    }
    if (!isEqual(prevProps.cols, this.props.cols)) {
      this.multiGrid.recomputeGridSize();
      this.multiGrid.forceUpdateGrids();
    }
  }

  renderCell = ({
    columnIndex,
    key,
    rowIndex,
    style,
  }: {
    columnIndex: number,
    key: string,
    rowIndex: number,
    style: StyleSheet,
  }) => {
    const col = this.props.cols[columnIndex];
    const row = this.props.rows[rowIndex - 1]; //offset - 1 to display headers
    return rowIndex === 0 ? (
      <CellHeader
        key={key}
        col={col}
        columnIndex={columnIndex}
        style={style}
        canColumnFilter={this.props.canColumnFilter}
        isColumnFiltered={this.props.isColumnFiltered}
        isColumnSorted={this.props.isColumnSorted}
        getColumnSortDirection={this.props.getColumnSortDirection}
        parseCellHeaderCaption={this.props.parseCellHeaderCaption}
        onHeaderClick={this.props.onHeaderClick}
        onHeaderFilterClick={this.props.onHeaderFilterClick}
        headerFilterTitle={this.props.headerFilterTitle}
      />
    ) : (
      <CellColumn
        key={key}
        col={col}
        columnIndex={columnIndex}
        rowIndex={rowIndex}
        hoveredRowIndex={this.state.hoveredRowIndex}
        activeRowIndex={this.state.activeRowIndex}
        isRowActive={this.props.isRowActive}
        style={style}
        row={row}
        isRowLoaded={this.props.isRowLoaded}
        loadMoreRows={this.props.loadMoreRows}
        onCellContextMenu={this.handleCellContextMenu}
        onCellClick={this.handleCellOnClick}
        onCellDoubleClick={this.props.onCellDoubleClick}
        onCellMouseOver={this.handleCellOnMouseOver}
        onCellMoreClick={this.props.onCellMoreClick}
        actionsDisabled={this.props.actionsDisabled}
        filterText={this.props.filterText}
        parseCellColumnTitle={this.props.parseCellColumnTitle}
        getCell={this.props.getCell}
        CellContent={this.props.CellContent}
        ContextMenuHandler={this.props.ContextMenuHandler}
      />
    );
  };

  handleCellOnClick = (
    e: SyntheticMouseEvent<*>,
    rowIndex: number,
    columnIndex: number,
    itemUri: string
  ) => {
    e.persist();
    this.setState({ activeRowIndex: rowIndex });
    this.multiGrid.forceUpdateGrids();
    e.stopPropagation();
    /* istanbul ignore else */
    if (typeof this.props.onCellClick === "function") {
      this.props.onCellClick(e, rowIndex, columnIndex, itemUri);
    }
  };

  handleCellOnMouseOver = (rowIndex: number) => {
    this.setState({ hoveredRowIndex: rowIndex });
    this.multiGrid.forceUpdateGrids();
  };

  handleCellContextMenu = (
    e: SyntheticMouseEvent<*>,
    rowIndex: number,
    columnIndex: number,
    itemUri: string
  ) => {
    this.setState({ activeRowIndex: rowIndex });
    this.multiGrid.forceUpdateGrids();
    e.preventDefault();
    e.persist();
    /* istanbul ignore else */
    if (typeof this.props.onContextClick === "function") {
      this.props.onContextClick(e, rowIndex, columnIndex, itemUri);
    }
  };

  handleSectionRendered = ({ rowStartIndex }: { rowStartIndex: number }) =>
    this.props.rowStartIndexUpdated(rowStartIndex);

  render() {
    const {
      rowCount,
      columnCount,
      cols,
      getColumnWidth,
      onCellMoreClick,
      scrollToRow,
      children,
    } = this.props;
    //
    if (cols.length == 0) {
      return null;
    }
    return (
      <Measure bounds>
        {({
          measureRef,
          contentRect: /* istanbul ignore next */ { bounds } = {
            bounds: { width: 0, height: 0 },
          },
        }) => (
          <div
            ref={measureRef}
            className={[this.props.className, styles.wrapper]
              .filter((c) => c != null)
              .join(" ")}
            data-test="gridList"
          >
            <MultiGrid
              scrollToAlignment="start"
              fixedColumnCount={0}
              fixedRowCount={1}
              scrollToColumn={0}
              cellRenderer={this.renderCell}
              estimatedColumnSize={250}
              columnWidth={({ index }) =>
                getColumnWidth({ index, col: cols[index] })
              }
              columnCount={columnCount}
              width={/* istanbul ignore next */ bounds?.width ?? 0}
              height={/* istanbul ignore next */ bounds?.height ?? 0}
              rowHeight={42}
              rowCount={rowCount + 1} // to always show header row
              style={STYLE}
              styleBottomLeftGrid={STYLE_BOTTOM_LEFT_GRID}
              styleTopLeftGrid={STYLE_TOP_LEFT_GRID}
              styleTopRightGrid={STYLE_TOP_RIGHT_GRID}
              ref={(ref) => (this.multiGrid = ref)}
              scrollToRow={scrollToRow}
              onSectionRendered={this.handleSectionRendered}
            />
            {children}
          </div>
        )}
      </Measure>
    );
  }
}
GridList.displayName = "GridList";
GridList.CellHeader = CellHeader;
GridList.CellColumn = CellColumn;

export default GridList;
