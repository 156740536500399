import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 16 16" fill="#999" {...props}>
    <path d="M8,10.16A2.16,2.16,0,1,1,10.16,8,2.16,2.16,0,0,1,8,10.16ZM8,6.49A1.51,1.51,0,1,0,9.51,8,1.52,1.52,0,0,0,8,6.49Z" />
    <path d="M8.72,13.18H7.28a.74.74,0,0,1-.72-.58l-.19-.81a3.76,3.76,0,0,1-.84-.49l-.79.25a.74.74,0,0,1-.87-.34L3.15,10a.76.76,0,0,1,.14-.92l.61-.56a4,4,0,0,1,0-1L3.29,7A.76.76,0,0,1,3.15,6l.72-1.24a.75.75,0,0,1,.87-.34l.79.25a3.76,3.76,0,0,1,.84-.49l.19-.81a.74.74,0,0,1,.72-.58H8.72a.74.74,0,0,1,.72.58l.19.81a3.76,3.76,0,0,1,.84.49l.79-.25a.75.75,0,0,1,.87.34L12.85,6a.76.76,0,0,1-.14.92l-.61.56a4,4,0,0,1,0,1l.61.56h0a.76.76,0,0,1,.14.92l-.72,1.24a.75.75,0,0,1-.87.34l-.79-.25a4,4,0,0,1-.84.49l-.19.81A.74.74,0,0,1,8.72,13.18ZM5.6,10.62a.31.31,0,0,1,.2.07,3.35,3.35,0,0,0,1,.56.34.34,0,0,1,.21.23l.22,1a.11.11,0,0,0,.09.08H8.72a.11.11,0,0,0,.09-.08l.22-1a.34.34,0,0,1,.21-.23,3.35,3.35,0,0,0,1-.56.33.33,0,0,1,.3-.06l.95.3a.09.09,0,0,0,.12,0l.72-1.24a.13.13,0,0,0,0-.13l-.73-.67a.31.31,0,0,1-.1-.29,3.6,3.6,0,0,0,0-.56,3.82,3.82,0,0,0,0-.57.3.3,0,0,1,.1-.28l.73-.67a.12.12,0,0,0,0-.13l-.72-1.24a.09.09,0,0,0-.12,0l-.95.3a.33.33,0,0,1-.3-.06,3.35,3.35,0,0,0-1-.56A.34.34,0,0,1,9,4.52l-.22-1a.11.11,0,0,0-.09-.08H7.28a.11.11,0,0,0-.09.08L7,4.52a.34.34,0,0,1-.21.23,3.35,3.35,0,0,0-1,.56.33.33,0,0,1-.3.06l-1-.3a.09.09,0,0,0-.12,0L3.71,6.35a.12.12,0,0,0,0,.13l.73.67a.3.3,0,0,1,.1.28,4.08,4.08,0,0,0,0,1.14.3.3,0,0,1-.1.28l-.73.67a.12.12,0,0,0,0,.13l.72,1.24a.09.09,0,0,0,.12,0l1-.3Z" />
    <path d="M.84,7.18A6.33,6.33,0,0,1,10.8,2H10a.42.42,0,0,0,0,.84h2a.42.42,0,0,0,.42-.42v-2a.43.43,0,0,0-.85,0V1.48A7.08,7.08,0,0,0,7.18,0,7.18,7.18,0,0,0,1,10.77a.44.44,0,0,0,.37.21.43.43,0,0,0,.36-.64A6.27,6.27,0,0,1,.84,7.18Z" />
    <path d="M15.16,8.87a6.35,6.35,0,0,1-6.38,6.29A6.28,6.28,0,0,1,5.16,14H6a.42.42,0,1,0,0-.84l-2,0a.42.42,0,0,0-.42.42v2A.41.41,0,0,0,4,16a.44.44,0,0,0,.43-.42v-1a7.17,7.17,0,0,0,9.44-.56,7.16,7.16,0,0,0,1.2-8.65.39.39,0,0,0-.36-.21.41.41,0,0,0-.21,0,.43.43,0,0,0-.16.58,6.28,6.28,0,0,1,.83,3.17Z" />
  </svg>
);
Icon.displayName = "Icon.CustomActionReminder";
export default Icon;
