const postMessage = (type) => {
    window.parent.postMessage({ source: "inPoint", type: type }, "*");
};

const useScrollHandler = (
    isFirstScroll,
    setIsFirstScroll,
    htmlViewerAction
) => {
    return (e) => {
        if (isFirstScroll) {
            setIsFirstScroll(false);
            return;
        }

        const docElement = e.target.documentElement;

        // if it's the top of the component
        if (docElement.scrollTop === 0) {
            postMessage(htmlViewerAction.Top);
        }

        // if it's the bottom of the component
        if (
            docElement.scrollHeight - Math.ceil(docElement.scrollTop) ===
            docElement.clientHeight
        ) {
            postMessage(htmlViewerAction.Bottom);
        }
    };
};

export default useScrollHandler;
