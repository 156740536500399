import React, { useEffect } from "react";
import { Components, FormAction } from "./FormBar";
import useIntersectionObserver from "../hooks/useIntersectionObserver";

export type ActionButtonProps = {
  Components: Omit<Components, "Container" | "Header">;
  action: FormAction;
  onIntersectionChange: (isIntersecting: boolean, action: FormAction) => void;
  className?: string;
  node?: Element;
};

const threshold = .999  //HACK: Use .999 to avoid rounding errors in Microsoft Edge

const ActionButton = ({
  Components,
  action,
  onIntersectionChange,
  className,
  node,
}: ActionButtonProps) => {
  const { setRef, entry } = useIntersectionObserver<HTMLButtonElement>({
    root: node,
    rootMargin: "10px 0px 10px 10px",
    threshold,
  });
  const isIntersecting = entry.intersectionRatio >= threshold;
  useEffect(() => {
    onIntersectionChange(isIntersecting, action);
  }, [isIntersecting, action, onIntersectionChange]);
  return (
    <button
      ref={setRef}
      title={action.description ?? action.title}
      onClick={action.onClick}
      className={`btn btn-link ${
        isIntersecting ? "intersecting" : "not-intersecting"
      }${className ? ` ${className}` : ""}`}
      data-test={`${action.id}Button`}
    >
      {action.icon && <Components.Icon name={action.icon} />}
      {action.title}
    </button>
  );
};

ActionButton.displayName = "ActionButton";
export default ActionButton;
