import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 427.81 499.41" fill="#e64a19" {...props}>
    <path
      d="M1.66,257.86q0-108.37,0-216.74C1.68,17.51,11,8.18,34.54,8.18c79.09,0,158.17.11,237.26-.08,22.36-.05,41,7.67,56.67,23.57q38.83,39.38,78.23,78.19,22.63,22.21,22.65,53.44.24,157.43,0,314.85c0,19.79-10.12,29.33-30.06,29.33q-183.29.05-366.59,0c-21.46,0-31-9.42-31-31.11Q1.56,367.12,1.66,257.86Zm391.8,213.72V186.4H285c-25,0-33.54-8.58-33.56-33.92q0-49,0-98.07c0-3.41,0-6.81,0-10.29H37.5V471.59ZM287.24,150.42h105c-3.06-4.9-4.88-9.39-8-12.56q-42-42.4-84.49-84.3c-3.09-3-7.47-4.79-12.48-7.9Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      d="M197.8,362.27v46.31h24.57V437H133.83c0-8-.33-15.78.26-23.49.13-1.69,3.46-4.25,5.52-4.45,6-.6,12.11-.21,18.71-.21V256.93H134.38V228.06c2.71-.18,5-.45,7.25-.45,32.94,0,65.87,0,98.81.15,30,.18,51.89,13.27,61,38,10.64,28.91,7.45,56.27-13.73,79.87-10.77,12-25.39,16.12-41,16.51C230.76,362.58,214.76,362.27,197.8,362.27Zm0-30c11.39,0,21.82.13,32.25,0,33.85-.51,42.62-24.83,36.27-51.32-2.61-10.89-9.87-18.58-20.68-20-15.65-2.1-31.57-2.17-47.84-3.13Z"
      transform="translate(-1.64 -8.1)"
    />
  </svg>
);
Icon.displayName = "Icon.FileExt.PowerPoint";
export default Icon;
