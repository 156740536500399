import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 498.36 499.41" {...props}>
    <polygon
      fill="#fff"
      className="invert-fill"
      points="22.36 17.9 297.36 17.9 407.36 138.9 407.36 476.9 22.36 476.9 22.36 17.9"
    />
    <path
      fill="#0099a9"
      d="M1.66,257.86q0-108.37,0-216.74C1.68,17.51,11,8.18,34.54,8.18c79.09,0,158.17.11,237.26-.08,22.36-.05,41,7.67,56.67,23.57q38.83,39.38,78.23,78.19,22.63,22.21,22.65,53.44.24,157.43,0,314.85c0,19.79-10.12,29.33-30.06,29.33q-183.29.05-366.59,0c-21.46,0-31-9.42-31-31.11Q1.56,367.12,1.66,257.86Zm391.8,213.72V186.4H285c-25,0-33.54-8.58-33.56-33.92q0-49,0-98.07c0-3.41,0-6.81,0-10.29H37.5V471.59ZM287.24,150.42h105c-3.06-4.9-4.88-9.39-8-12.56q-42-42.4-84.49-84.3c-3.09-3-7.47-4.79-12.48-7.9Z"
      transform="translate(-1.64 -8.1)"
    />
    <rect fill="#0099a9" x="62.36" y="219.9" width="436" height="210" />
    <path
      fill="#fff"
      className="invert-fill"
      d="M264,264.83l-34.55,140H196.82L175.13,315a79.71,79.71,0,0,1-2.1-15.72h-.36A101.76,101.76,0,0,1,170.29,315l-22.24,89.81h-34l-34.36-140h32.18l18.41,93.23a119.05,119.05,0,0,1,1.73,16h.55a82.46,82.46,0,0,1,2.64-16.4l23.7-92.84h31.54l21.42,94a123,123,0,0,1,2.1,15h.36a107.28,107.28,0,0,1,1.91-15.62l18-93.42Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      fill="#fff"
      className="invert-fill"
      d="M365.68,290.5H318.23v34.36h43.6v25.58h-43.6v54.38H287.95v-140h77.73Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      fill="#fff"
      className="invert-fill"
      d="M477.48,404.82H397.41v-140h30.29V379.24h49.79Z"
      transform="translate(-1.64 -8.1)"
    />
  </svg>
);
Icon.displayName = "Icon.WorkflowReport";
export default Icon;
