import React from "react";

const Icon = (props: CSSStyleDeclaration) => (
  <svg viewBox="0 0 427.81 499.41" fill="#1b1464" {...props}>
    <path
      d="M1.66,257.86q0-108.37,0-216.74C1.68,17.51,11,8.18,34.54,8.18c79.09,0,158.17.11,237.26-.08,22.36-.05,41,7.67,56.67,23.57q38.83,39.38,78.23,78.19,22.63,22.21,22.65,53.44.24,157.43,0,314.85c0,19.79-10.12,29.33-30.06,29.33q-183.29.05-366.59,0c-21.46,0-31-9.42-31-31.11Q1.56,367.12,1.66,257.86Zm391.8,213.72V186.4H285c-25,0-33.54-8.58-33.56-33.92q0-49,0-98.07c0-3.41,0-6.81,0-10.29H37.5V471.59ZM287.24,150.42h105c-3.06-4.9-4.88-9.39-8-12.56q-42-42.4-84.49-84.3c-3.09-3-7.47-4.79-12.48-7.9Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      d="M348.5,440H82.67c0-16.1-.06-31.85.1-47.6a7.11,7.11,0,0,1,2.2-4.27c15.63-15.79,31.35-31.49,47.35-47.51L164.5,373.5,266,271.91c5.91,6.6,10.45,12.13,15.46,17.18,20.94,21.12,42.09,42,63,63.21,2.31,2.35,4.63,6,4.67,9.06.36,25,.18,50,.12,75A22.07,22.07,0,0,1,348.5,440Z"
      transform="translate(-1.64 -8.1)"
    />
    <path
      d="M82.73,256.72A49.72,49.72,0,1,1,132,306.91,49.65,49.65,0,0,1,82.73,256.72Z"
      transform="translate(-1.64 -8.1)"
    />
  </svg>
);
Icon.displayName = "Icon.FileExt.Image";
export default Icon;
