//@flow
import React, { useCallback, type StatelessFunctionalComponent } from "react";
import { type GridListColumnResultDTO } from "@hs/types";
import Icons from "@hs/icons";
import styles from "./CellColumn.module.css";
type Props = {
  col: GridListColumnResultDTO,
  columnIndex: number,
  rowIndex: number,
  hoveredRowIndex: number,
  activeRowIndex: number,
  style: StyleSheet,
  row: Array<any>,
  isRowLoaded: ({ rowIndex: number }) => boolean,
  loadMoreRows: ({ rowIndex: number }) => void,
  onCellContextMenu: (
    e: SyntheticMouseEvent<*>,
    dataRowIndex: number,
    columnIndex: number,
    itemUri: ?string
  ) => void,
  onCellClick: (
    e: SyntheticMouseEvent<*>,
    dataRowIndex: number,
    columnIndex: number,
    itemUri: ?string
  ) => void,
  onCellDoubleClick: (
    e: SyntheticMouseEvent<*>,
    itemUri: ?string,
    dataRowIndex: number
  ) => void,
  onCellMouseOver: (dataRowIndex: number) => void,
  onCellMoreClick: (
    e: SyntheticMouseEvent<*>,
    itemUri: ?string,
    dataRowIndex: number
  ) => void,
  actionsDisabled: boolean,
  filterText: string,
  parseCellColumnTitle: (rawData: string, cell: Array<any>) => string,
  CellContent: React.MixedElement,
  ContextMenuHandler: React.MixedElement,
  getCell: ({
    row: Array<any>,
    columnIndex: number,
  }) => Array<any>,
  isRowActive: (rowIndex: number, defaultState: boolean) => boolean,
};

const CellColumn: StatelessFunctionalComponent<Props> = ({
  col,
  columnIndex,
  rowIndex,
  hoveredRowIndex,
  activeRowIndex,
  style,
  isRowLoaded,
  loadMoreRows,
  onCellContextMenu,
  onCellClick,
  onCellDoubleClick,
  onCellMouseOver,
  onCellMoreClick,
  actionsDisabled,
  filterText,
  parseCellColumnTitle,
  CellContent,
  ContextMenuHandler,
  row = [],
  getCell = ({ row, columnIndex }) => row[columnIndex],
  isRowActive = (rowIndex, defaultState) => defaultState,
}: Props) => {
  const { caption: header } = col;
  const dataRowIndex = rowIndex - 1;
  const itemUri = row.length > 0 ? row[row.length - 1] : null;
  const ext = row.length > 0 ? row[0] : null;
  const handleCellContextMenu = useCallback(
    (e) => onCellContextMenu(e, dataRowIndex, columnIndex, itemUri),
    [dataRowIndex, columnIndex, itemUri, onCellContextMenu]
  );
  const handleCellClick = useCallback(
    (e) => onCellClick(e, dataRowIndex, columnIndex, itemUri),
    [dataRowIndex, columnIndex, itemUri, onCellClick]
  );
  const handleCellDoubleClick = useCallback(
    (e) => onCellDoubleClick(e, itemUri, dataRowIndex),
    [dataRowIndex, itemUri, onCellDoubleClick]
  );
  const handleCellMouseOver = useCallback(
    () => onCellMouseOver(dataRowIndex),
    [dataRowIndex, onCellMouseOver]
  );

  /* istanbul ignore next */
  const handleCellMoreClick = useCallback(
    (e) => onCellMoreClick(e, itemUri, dataRowIndex),
    [itemUri, dataRowIndex, onCellMoreClick]
  );

  /* istanbul ignore next */
  const handleActionButtonClick = useCallback(
    (e) => actionsDisabled || onCellMoreClick(e, itemUri, dataRowIndex),
    [actionsDisabled, itemUri, dataRowIndex, onCellMoreClick]
  );

  if (!isRowLoaded({ rowIndex: dataRowIndex })) {
    loadMoreRows({ rowIndex: dataRowIndex });
    return (
      <div style={style} className={styles.loading}>
        <Icons.Library name="fa-solid fa-spinner" pulse />
      </div>
    );
  }

  const isActive = isRowActive(dataRowIndex, dataRowIndex === activeRowIndex);

  const classNames = [styles.cell];
  if (dataRowIndex === hoveredRowIndex) classNames.push(styles.hoveredItem);
  if (isActive) classNames.push(styles.active);
  if (columnIndex === 0) classNames.push(styles.ext);

  const cell = getCell({ row, columnIndex });
  return (
    <ContextMenuHandler
      componentClass="div"
      onContextMenu={handleCellContextMenu}
      className={classNames.join(" ")}
      title={parseCellColumnTitle(header, cell)}
      style={style}
      onMouseOver={handleCellMouseOver}
      onClick={handleCellClick}
      onDoubleClick={handleCellDoubleClick}
      rowIndex={dataRowIndex}
      columnIndex={columnIndex}
      data-test={isActive ? `rowNr-active-${activeRowIndex}` : undefined}
    >
      <CellContent
        index={dataRowIndex}
        col={col}
        cell={cell}
        filterText={filterText}
        onMoreClick={handleCellMoreClick}
        actionsDisabled={actionsDisabled}
        isActive={isActive}
        isHovered={dataRowIndex === hoveredRowIndex}
        itemUri={itemUri}
        ext={ext}
      />
    </ContextMenuHandler>
  );
};
CellColumn.displayName = "GridList.CellColumn";

export default CellColumn;
