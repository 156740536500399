//@flow
import React, { useCallback, useMemo, type StatelessFunctionalComponent } from "react";
import {
  type GridListColumnResultDTO,
  type SortDirectionEnum,
} from "@hs/types";
import Icons from "@hs/icons";
import loc from "i18next";
import styles from "./CellHeader.module.css";

type Props = {
  col: GridListColumnResultDTO,
  columnIndex: number,
  style: StyleSheet,
  canColumnFilter: (name: string) => boolean,
  isColumnFiltered: (name: string) => boolean,
  isColumnSorted: (name: string) => boolean,
  getColumnSortDirection: (name: string) => SortDirectionEnum,
  parseCellHeaderCaption: (caption: string) => string,
  onHeaderClick: (columnIndex: number, header: string) => void,
  onHeaderFilterClick: (e: SyntheticMouseEvent<*>, name: string) => void,
  headerFilterTitle: ?string,
};

const CellHeader: StatelessFunctionalComponent<Props> = ({
  col,
  columnIndex,
  style,
  canColumnFilter,
  isColumnFiltered,
  isColumnSorted,
  getColumnSortDirection,
  onHeaderClick,
  onHeaderFilterClick,
  parseCellHeaderCaption,
  headerFilterTitle
}: Props) => {
  const { caption: header, name } = col;
  const handleHeaderClick = useCallback(
    (e) => onHeaderClick(columnIndex, header),
    [columnIndex, header, onHeaderClick]
  );
  const handleHeaderFilterClick = useCallback(
    (e) => onHeaderFilterClick(e, name),
    [name, onHeaderFilterClick]
  );
  const isFiltered = isColumnFiltered(name);
  const colStyle = useMemo(
    () => (isFiltered ? { opacity: 1 } : { opacity: 0.2 }),
    [name, isFiltered]
  );

  const caption = parseCellHeaderCaption(header);

  /* istanbul ignore next */
  const filterTitle = useMemo(() => {
    return headerFilterTitle
      ? headerFilterTitle
      : loc.t("document:filterBy", {
          name: caption,
        });
  }, [headerFilterTitle, caption]);

  return (
    <div
      className={styles.header}
      style={style}
      title={caption}
      onClick={handleHeaderClick}
    >
      {col.canSort && (
        <Icons.Sort
          pullRight
          isSorted={isColumnSorted(col.name)}
          sortDirection={getColumnSortDirection(col.name)}
          name={caption}
        />
      )}
      {col.canSort && canColumnFilter(col.name) && (
        <Icons.Library
          title={filterTitle}
          name="fa-solid fa-filter"
          onClick={handleHeaderFilterClick}
          className={styles.filter}
          style={colStyle}
        />
      )}
      {caption}
    </div>
  );
};
CellHeader.displayName = "GridList.CellHeader";

export default CellHeader;
